import { useTheme } from '@emotion/react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

const HeroText = ({ children }) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation()
    return (
        <>
            <Box color={'primary.main'} sx={{ display:'flex', flexDirection:'column', gap:2, alignItems:'center', textAlign: isMobile && 'center' }} >
                <Typography sx={{ zIndex: '99', position: 'relative' }} variant={isMobile ? 'h6' : 'h4'} >{t("Hero.title")}</Typography>
                <Typography sx={{ zIndex: '99', position: 'relative' }} variant='h5' ><span style={{ color: '#FABB25' }} >{t("Hero.ecoom")} </span> {t("Hero.ecoomC")} </Typography>
                <Typography sx={{ zIndex: '99', position: 'relative' }}>{t("Hero.supTitle")}</Typography>
                <Box sx={{ display:'flex', gap:2, width:'90%', justifyContent:'center' }} >
                    <Button sx={{
                        backgroundColor: '#FABB25', color: '#000',
                        ':hover': {
                            backgroundColor: '#FABB25'
                        }, width: { md: '125px', xs: '100%' },
                        py:1.2
                    }} onClick={() => navigate('/start-now') } >{t("Hero.start")}</Button>
                    <Button sx={{
                        backgroundColor: '#fff', color: '#000',
                        ':hover': {
                            backgroundColor: '#fff'
                        }, width: { md: '125px', xs: '100%' },
                        py:1.2
                    }} onClick={() => navigate('/service/9') } >{t("Hero.package")}</Button>
                </Box>
                {children}
                <Typography sx={{ display:{md:'unset', xs:'none'} }} >{t("Hero.searchDesc")}</Typography>

            </Box>
        </>
    )
}

export default HeroText