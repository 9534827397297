import { Typography } from '@mui/material'
import React from 'react'

const ServiceLest = ({serviceTo, item1, item2, item3}) => {
    return (
        <>
            <Typography variant={'h6'} >{serviceTo}</Typography>
            <ul className='myList' >
                <li>{item1}</li>
                <li>{item2}</li>
                <li>{item3}</li>
            </ul>
        </>
    )
}

export default ServiceLest
