import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import ico from './../../assets/StartNow/Icon awesome-check-double.png'
const Item = ({ Text }) => {
    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', alignItems:'baseline', gap:2  }} >
                    <CardMedia
                    alt='test'
                        component={'img'}
                        src={ico}
                        sx={{ width: '15px', height: '15px', objectFit: 'contain' }}
                    />
                    <Typography my={2} >{Text}</Typography>
                </Box>
            </Box>
        </>
    )
}

export default Item