import { Box, Button, Container, Grid} from '@mui/material'
import React from 'react'
import './contact.css'
import { useTranslation } from 'react-i18next'
import Card from './Card'
import i1 from './../../assets/contact/1.png'
import i2 from './../../assets/contact/2.png'
import i3 from './../../assets/contact/3.png'
import i4 from './../../assets/contact/4.png'
import { useNavigate } from 'react-router-dom'

const ContactUs = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <>
            <Container maxWidth={'lg'}>
                <Box sx={{
                    '@media (min-width: 1440px)': {
                        pb:'4rem'
                    } }}>
            <Grid container spacing={2} >
                <Grid item md={6} xs={12}><Card 
                    icon={i1}
                    title={t("contact.one")}
                    dir={'75%'}
                    /></Grid> 
                <Grid item md={6} xs={12}><Card 
                    icon={i2}
                    title={t("contact.tow")}
                    dir={'0%'}
                    /></Grid> 
                <Grid item md={6} xs={12}><Card 
                    icon={i3}
                    title={t("contact.three")}
                    dir={'75%'}
                    /></Grid> 
                <Grid item md={6} xs={12}><Card 
                    icon={i4}
                    title={t("contact.four")}
                    dir={'0%'}
                    /></Grid> 
            </Grid>
                    <Box sx={{ textAlign:'center' }} >
                        <Button sx={{
                            backgroundColor: '#FABB25', color: '#000',
                            ':hover': {
                                backgroundColor: '#FABB25'
                            }, width: 'fit-content',
                            textAlign: 'center',
                            mb: 2,
                            py:1
                    }} onClick={() => navigate('/booking')} >{t("contact.btn")}</Button> 
                    </Box> 
                </Box>
            </Container>
        </>
    )
}

export default ContactUs
