import React, { useState } from 'react';
import { Box, Typography, Menu, MenuItem, useMediaQuery } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { services } from '../../constants';
import i18next from 'i18next';
import { useTheme } from '@emotion/react';

const Filter = ({ setIdService, idService = 0 }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [sliceEnd, setSliceEnd] = useState(isMobile ? 2 : 4); 
    const [anchorEl, setAnchorEl] = useState(null); 
    const open = Boolean(anchorEl);

    let lng = i18next.language;

    function handleIdService(id) {
        setIdService(id);
    }


    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuItemClick(id) {
        handleIdService(id);
        setAnchorEl(null);
        isMobile ? setSliceEnd(2) : setSliceEnd(4);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <Box textAlign={'center'} sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }} >
                <Typography
                    onClick={() => {
                        handleIdService(0);
                        setSliceEnd(4); 
                    }}
                    sx={{
                        '&:hover': { color: idService === 0 ? '#000' : '#ccc' }, cursor: 'pointer',
                    }}>{lng === 'en' ? 'all' : 'الجميع'}</Typography>
                {
                    services.slice(1, sliceEnd).map((service, index) =>
                        <Typography
                            key={service.id}
                            onClick={() => { handleIdService(service.id); setSliceEnd(4); }}
                            sx={{
                                color: service.id === idService ? '#000' : '#ccc',
                                '&:hover': { color: '#000' }, cursor: 'pointer',
                            }}>
                            {lng === 'en' ? service.nameEn : service.name}
                        </Typography>
                    )
                }
                {services.length > 4 &&
                    <Box>
                        <MoreHorizIcon onClick={handleMenuClick} sx={{ cursor:'pointer' }} />
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'more-menu',
                            }}
                        >
                            {services.slice(isMobile ? 2 : 4).map((service) => (
                                <MenuItem key={service.id} onClick={() => handleMenuItemClick(service.id)}>
                                    {lng === 'en' ? service.nameEn : service.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                }
            </Box>
        </>
    )
}

export default Filter;
