import React from 'react'
import { Outlet } from "react-router-dom";
import NavBar from "./../components/NavBar";
import { Box } from '@mui/material';
import Footer from '../components/footer/Footer';
function Layout() {
    return (
        <Box>
            <NavBar />
            <Outlet />
            <Footer />
        </Box>
    )
}

export default Layout