import { Box, CardMedia, Container, Grid, Rating, Typography } from '@mui/material'
import React from 'react'
import img from './../../assets/reviews/Group 1786.png'
import Slider from '../Slider'
import icon from './../../assets/Icon open-command.png' 
import { useTranslation } from 'react-i18next'
const Reviews = () => {
    const {t} = useTranslation()
    return (
        <>
            <Container maxWidth={'lg'} >
                <Grid container spacing={1} sx={{ alignItems:'center' }} >
                    <Grid item md={4} xs={12}>
                        <CardMedia  alt='test'
                            component={'img'}
                            src={img}
                            sx={{ width: '100%', margin: '0 auto' }}
                        />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Box sx={{ position:'relative', width:'75%', margin:'auto' }} >
                            <Typography variant='h5' my={2} color={'#4C164C'} sx={{ fontWeight: 'bold' }}>
                                {t("reviews.title")}
                            </Typography>
                            <CardMedia  alt='test' component={'img'} src={icon} sx={{ width: '30px', position:'absolute', left:'-10%', top:'50%', objectFit:'contain' }} />
                            <Slider state={false}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                                    <Typography  >كل الاحترام والتقدير لكم  شركة محترمه ومتعاونه في الخدمات التي تقدمها.</Typography>
                                    <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                                    <Typography>محمد بن زايد</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                                    <Typography  >انا فخور جداً بتعاملي معكم ونشكركم على تعاونكم الدائم والخدمة المميزة التي تقدموها خدمة مميزة ومبتكرة ومتميزة جدا واتمنى لكم دوام النجاح والتفوق والتميز في العمل.</Typography>
                                    <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                                    <Typography>فهد القحطاني</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                                    <Typography  >شكرا شركة مزيد على حسن التعامل والاخلاق الحسنه وجوده تنفيذ الاعمال المطلوبه فى الاوقات المحددة،  والإنحاز بشكل احترافى يليق بهم.
                                        كل الشكر والتقدير لحضراتكم جميعاً</Typography>
                                    <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                                    <Typography>بدر المطيري</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                                    <Typography  >شكرا شركة مزيد وسعيد جدا بالتعامل معاكم  والصراحة كان التعامل باحترافيه وكل احترام وارشح كل اللى يبدا في مجال الاونلاين وعندو بيزنس جديد انو يبدأ معاهم لانهم فعلاً ناس فاهمه وعندها بلان
                                        بشكركم جدا واتمنلكم كل التوفيق 🌹</Typography>
                                    <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                                    <Typography>عبدالله الدوسري</Typography>
                                </Box>
                            </Slider>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Reviews