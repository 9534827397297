
import { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ScrollTo from './components/Ui/ScrollToTop';
import Home from './pages/Home';
// import NavBar from './components/NavBar';
// import Footer from './components/footer/Footer';
import Services from './pages/Services';
import Service from './pages/Service';
// import OurWork from './pages/OurWork';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Booking from './pages/Booking';
// import SotialFooter from './components/glopal/SotialFooter';
import StartNow from './pages/StartNow';
// import Work from './pages/Work';
import PrivacyPolicy from './pages/PrivacyPolicy';
import InstitutionPolicy from './pages/InstitutionPolicy';
import SiteMap from './pages/SiteMap';
import Blog from './pages/Blog';
import SupmitDone from './pages/SupmitDone';  
import Layout from './pages/Layout';
import Landing from './pages/Landing';

const languages = [
  {
    code: 'ar',
    name: 'Ar',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'En',
    country_coode: 'gb',
    dir: 'ltr'
  }
]


function App() {

  const currentLanguageCode = Cookies.get('i18next') || 'ar'; 
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode]) 
  const { t } = useTranslation(); 
  useEffect(() => {

    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar'); 

  }, [currentLanguage, t,]);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]); 

    return null;
  };
  return (
    <div className="App"> 
      <CssBaseline />
      {/* <NavBar /> */}
      <ScrollToTop />
      <ScrollTo />
      {/* <SotialFooter /> */}
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Home />} /> 
        <Route path="/services" element={<Services />} /> 
        <Route path="/service/:id" element={<Service />} />
        {/* <Route path="/resources" element={<OurWork />} />
        <Route path="/resource/:id" element={<Work />} /> */}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/start-now" element={<StartNow />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/institution" element={<InstitutionPolicy />} />
        <Route path="/site-map" element={<SiteMap />} />
        {/* SuccessPage */}
        <Route path="/done" element={<SupmitDone />} />
        </Route>
        <Route path="/marketing" element={<Landing />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
