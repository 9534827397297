import React from 'react'
import './ScrollToTop.css'
import i18next from 'i18next';
import { Avatar, Box, Stack } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function ScrollTo() {
    let lng = i18next.language
    React.useEffect(() => {
        let span = document.querySelector(".up");
        window.onscroll = function () {
            this.scrollY >= 1000 ? span.classList.add("show") : span.classList.remove("show");
        };
        span.onclick = function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
    }, []);
    const bottomToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        
    
    
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: '10px', mx: '0', alignItems: 'center', position: 'fixed', width: '100%', top: '90%', flexDirection:'row-reverse', zIndex:999 }} >
            <Stack direction="column" spacing={2} sx={{
                zIndex: "9999",
                position: "relative",
                top: 'auto',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { md: 3, xs: 1 }, justifyContent: { md: 'center', xs: 'space-evenly' }, pb: { md: 0, xs: 1 }, mt: 2 }}>
                    <a href="/"
                        target='_blank' rel='noreferrer'>
                        <Avatar sx={{ width: "50px", height: "50px", backgroundColor: "#25D366" }} >
                            <WhatsAppIcon sx={{ fontSize: '2.5rem' }} />
                        </Avatar>
                        {/* <WhatsAppIcon sx={{ color: 'green', fontSize: '2.5rem' }} /> */}
                    </a>
                </Box>
            </Stack>
        <span onClick={bottomToTop} className={lng === 'ar' ? 'up' : 'up'}>
            &#8593;
        </span>
        </Box>
    )
}

export default ScrollTo