import { Box, Typography } from '@mui/material'
import React from 'react'

const ArticleService = ({ title, desc, because, children }) => {
  return (
    <>
      <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }} >
        {
          title &&
          <Typography variant='h6' textAlign={'center'} >{title}</Typography>
        }
        {
          desc &&
          <Typography>{desc}</Typography>
        }
        {
          because &&
          <Typography>{because}</Typography>
        }
        {children}
      </Box>
    </>
  )
}

export default ArticleService