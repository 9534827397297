import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import icon from './../../../assets/Group 2312.png'
const Item = ({ title, dir='row' }) => {
    return (
        <>
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '5%', flexDirection: { md: 'row-reverse', xs: dir } }}>
                <Box sx={{ border: '1px solid #DCDCDC', width: { md: '350px' ,xs:'350px'}, py: 1, px: 3, borderRadius: '8px',  }} >
                    <Typography color={'#4C164C'} >{title}</Typography>
                </Box>
                <Box sx={{  background:'#F6F5F5', p:1, borderRadius:'50%' }} >
                    <CardMedia 
                    className={'icon'}
                        component={'img'}
                        src={icon}
                        
                    />
                </Box>
            </Box>
        </>
    )
}

export default Item