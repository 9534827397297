import { Box, Radio, Typography } from '@mui/material';
import React from 'react';

const InableForm = ({ selectedValue, handleChange, formId, title }) => { 

    const handleRadioChange = (event) => {
        handleChange(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} >
            <Typography variant="h5" color={"secondary.main"} >
                {title}
            </Typography>
            <Radio
                checked={selectedValue === formId} // تم تحديث القيمة المحددة للراديو
                onChange={handleRadioChange}
                value={formId} // تم تحديث قيمة الراديو لتكون formId
                name="color-radio-button-demo"
                inputProps={{ 'aria-label': formId }}
                color="success"
            />
        </Box>
    );
}

export default InableForm;
