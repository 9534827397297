import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import Content from './Content'
import Pages from './Pages'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer style={{ padding: '20px', background: '#4C164C' }} >
            <Container maxWidth={'lg'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { md: 'row', xs: 'column' }, gap: { md: 0, xs: 2 }, my: 1 }} >
                    <Pages />
                    <Content />
                </Box>
                <Typography sx={{ textAlign: 'center', color: '#fff', fontSize: '0.8rem' }}>
                    {t("copeRight")}
                </Typography>
            </Container>
        </footer>
    )
}

export default Footer