import { Box, CardMedia, Container, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import img from './../../assets/ServicesYouDeserve/Group 2350.png'
import imgMob from './../../assets/ServicesYouDeserve/Group 2361.png'
import YouDeserveDesc from './YouDeserveDesc'
import { useTheme } from '@emotion/react'
const ServicesYouDeserve = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ backgroundColor: '#FFF4DC', borderTopLeftRadius: '26px', borderTopRightRadius:'26px',p:2, mt:2, pb:0, position:'relative', overflow:'hidden'  }} >
                <Container maxWidth={'lg'} >
                    <Grid container sx={{ alignItems: 'center', zIndex: '99', flexDirection: isMobile && 'column-reverse' }} >
                        <Grid item md={6} xs={12}>
                            <Box sx={{ height:{md:'100%', xs:'250px'} }} >
                                <CardMedia
                                    alt='test'
                                    component={'img'}
                                    src={isMobile ? imgMob : img}
                                    sx={{ width: isMobile ? '80%' : '75%', margin: '0', height: isMobile ? '300px' : '100%', objectFit: 'contain', position: isMobile && 'absolute', left:{md:0, xs:'-45px'}, bottom:'-5px' }}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <YouDeserveDesc />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default ServicesYouDeserve