import React from 'react'
import Item from './Item'
import { ListPackages } from '../../../constants'
const ListItem = () => {
    return (
        <>
            <ul style={{ listStyle:'none', padding:0 }}  >
                {ListPackages?.map((item) => <li key={item?.id} ><Item Text={item?.Text} /></li>)} 
            </ul>
        </>
    )
}

export default ListItem