import { Grid } from '@mui/material'
import React from 'react'

const ListCardServices = ({ children }) => {
    return (
        <>
            <Grid container>
                {children}
            </Grid>
        </>
    )
}

export default ListCardServices