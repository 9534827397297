import { CardMedia, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import HeroText from './HeroText'
import './hero.css'
import Search from './Search'
import img from './../../assets/hero/Group 1673.svg'
import i18next from 'i18next'
import { useTheme } from '@emotion/react'
import ParticleBackground from '../glopal/ParticleBackground'
const Hero = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    let lng = i18next.language
    return (
        <>
            <div className="gradient">
                <ParticleBackground />
                {
                    !isMobile &&
                    <>
                        <div className={lng === 'ar' ? "test2" : "test2En"}></div>
                        <div className={lng === 'ar' ? "test1" : "test1En"}></div>
                    </>
                }
                <Grid container sx={{ alignItems: 'center', }} >
                    {
                        !isMobile &&
                    <Grid item md={6} xs={12}>
                        <CardMedia
                            alt='test'
                            component={'img'}
                            src={img}
                            sx={{ width: '70%', margin: '0 auto', zIndex: '99', position: 'relative' }}
                        />
                    </Grid>
                    }
                    <Grid item md={6} xs={12}>
                        <HeroText>
                            <Search />
                        </HeroText>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Hero