import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.css'
const IncreaseYourIncome = () => {
    const { t } = useTranslation()
    return (
        <>
            <Container maxWidth={'lg'}>
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 1, alignItems: 'center', justifyContent: { md: 'flex-start', xs: 'center' }, position: 'relative', p: { md: '0 16px', xs: 2 }, width: { md: '65%', xs: 'unset' } }} className='line' >
                    <Box textAlign={'center'} sx={{ width: { md: '50%', xs: 'unset' } }}>
                        <Typography
                            variant='h6'
                            sx={{ color: '#444242' }}
                        >{t("IncreaseYourIncome.title")}</Typography>
                        <Typography sx={{ color: '#444242' }}>{t("IncreaseYourIncome.supTitle")}</Typography>
                        <Typography sx={{ color: '#444242', textDecoration: 'line-through', opacity: '40%' }}>{t("IncreaseYourIncome.lineThrough")}</Typography>
                        <Box textAlign={'center'} sx={{ display: {md:'flex', xs:'block'}, alignItems:'center', gap:1, justifyContent:'center' }} >
                            <Typography color={'secondary.main'} variant='h6' >{t("IncreaseYourIncome.price")}</Typography>
                            <Typography color={'secondary.main'} variant='h6' >{t("IncreaseYourIncome.Unit")}</Typography>
                        </Box>
                    </Box>
                    <Box textAlign={'center'} sx={{ pb:2, width:{md:'50%', xs:'unset'} }} >
                        <Button sx={{
                            backgroundColor: '#000', color: '#fff',
                            ':hover': {
                                backgroundColor: '#000',
                                color: '#fff',
                            }, width: { md: '75%', xs: '70%' },
                            margin: 'auto', borderRadius: '0',
                            transform:'skewY(-5deg)'
                        }} >{t("IncreaseYourIncome.btn1")}</Button>
                        <Button sx={{
                            backgroundColor: '#FABB25', color: '#fff',
                            ':hover': {
                                backgroundColor: '#FABB25',
                                color: '#fff',
                            }, width: { md: '90%', xs: '90%' },
                            margin: 'auto', borderRadius: '0',
                            transform:'skewY(-5deg)'
                        }} >{t("IncreaseYourIncome.btn2")}</Button>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default IncreaseYourIncome
