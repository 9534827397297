import { Box, Button, Typography } from '@mui/material'
import React from 'react'
function SupmitDone() {
    const goBack = () => {
        window.history.back();
    };

    return ( 
        <>
            <Box sx={{
                margin: { md: "15px", xs: "5px" },
                height: "85vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                color: "#ffb000",
                textAlign: "center",
                // fontSize: "2rem",
                // width: "100vw",
                flexDirection: "column",
            }} >
                <Typography sx={{

                }} >
                    Thank you ..<br />
                    Your request has been sent successfully.. <br />
                    We will contact you as soon as possible
                </Typography>
                <Box pb={2} textAlign={'center'} >
                    <Button variant="contained"
                        onClick={goBack}
                        sx={{
                            backgroundColor: '#FABB25', ':hover': {
                                backgroundColor: '#FABB25'
                            },
                            margin: "auto",
                            color: "#000",
                            fontWeight: 'bold',
                            width: '100px',
                            textAlign: 'center',

                        }}>Done</Button>
                </Box>
            </Box>
        </>
    )
}

export default SupmitDone