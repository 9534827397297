import { Box, CardMedia, Divider, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/logo.png'
import './style.css'
const Header = () => {
  return (
    <>
      <div className="hero">
        <Box className={'logo'} sx={{ m:1, }}>
          <CardMedia
            src={logo}
            component={'img'}
            sx={{  objectFit:'contain' }}
          />
        </Box>
          <Box className={'text'}>
            <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '25%', mb: 1 }} />
          <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '50%' }} />
            <Typography variant={'h5'} color={'primary.main'} mb={1} >هل التسويق  <br />
              يشكل لك تحديًا صعب ؟</Typography>
            <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '50%', mb: 1 }} />
            <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '25%' }} />
          </Box>
      </div>
    </>
  )
}

export default Header