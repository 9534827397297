import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import CardOffer from './CardOffer'
import { Offers } from '../../constants'
import Slider from '../Slider'
import { useTheme } from '@emotion/react'

const WhatWeWillOfferYou = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Container maxWidth={'lg'} sx={{ my: 2 }}>
                <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                    <Typography mb={2} variant='h4' color={'#F0AF3C'} >{'ماذا'} <span style={{ color: '#4C164C' }} > سنقدم لك </span> </Typography>
                </Box>
                {
                    isMobile ?
                        <Slider state={false}>
                            {
                                Offers.map((Offer) =>
                                    // <Box key={Offer?.id}>
                                    <CardOffer
                                        img={Offer.image}
                                        title={Offer.name}
                                    />
                                    // {/* </Box> */}
                                )
                            }
                        </Slider>
                        :
                        <Grid container spacing={1}>
                            {
                                Offers.map((Offer) =>
                                    <Grid item md={3} xs={12} key={Offer?.id}>
                                        <CardOffer
                                            img={Offer.image}
                                            title={Offer.name}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                }
            </Container>
        </>
    )
}

export default WhatWeWillOfferYou