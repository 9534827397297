import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const api = createApi({
  baseQuery: fetchBaseQuery({
    mode: 'cors',
    baseUrl: 'https://slots.revampbrands.com/api/v1',
  }),

  reducerPath: "apiSlot",
  tagTypes: ["Slot"],
  endpoints: (build) => ({
    getSlot: build.query({
      query: ({ day }) => `/slots-for-date?day=${day}`,
      providesTags: ["Slot"],
    }),
    addSlot: build.mutation({
      query: (formData) => ({
        url: '/booking',
        method: 'POST',
        body: formData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Slot'],
    }),
  }),
});


export const {
  useGetSlotQuery,
  useAddSlotMutation,
} = api;