import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
// import Card from './Card'
// import img1 from './../../../assets/StartNow/market-analysis.png' 
// import img2 from './../../../assets/StartNow/local-area-network.png' 
// import img3 from './../../../assets/StartNow/app-development.png' 
// import img4 from './../../../assets/StartNow/search.png'
import BasicAccordion from '../../BasicAccordion/BasicAccordion'
const Advantages = () => {
    return (
        <>
            <Container maxWidth={'lg'}>
                <Typography sx={{ my: 2, textAlign: { md: 'inherit', xs: 'center' }, fontSize:'bold', mt:5 }} variant='h5' >
                    <span style={{ color: '#FABB25' }} >بعض الأسئله الشائعه ؟ </span>
                </Typography>
                <Box>
                    <BasicAccordion />
                    {/* <Grid container  spacing={2} sx={{ justifyContent:'center', alignItems:'center' }} >
                        <Grid item md={3} xs={12}><Card
                        img={img1}
                            title={`التسويق الإعلاني`}
                            desc={`سنسمح لك بالتخطيط وإنشاء جميع 
الاستراتيجيات الممكنة لتسويق منتجاتك
 وخدماتك بشكل خلاق للعملاء وزيادة
 العملاء المحتملين.`}
                        /> </Grid>
                        <Grid item md={3} xs={12}><Card
                        img={img2}
                            title={`تطوير الشبكة`}
                            desc={`سنوفر لك جميع تسهيلات تطوير الويب
 لمساعدتك في إنشاء موقع ويب محسن 
واحترافي وعالي التحويل.`}
                        /> </Grid>
                        <Grid item md={3} xs={12}><Card
                        img={img3}
                            title={`تطوير التطبيقات`}
                            desc={`احصل على موارد مفيدة فورية لتحويل
 موقع الويب الخاص بك وعملك إلى
 تطبيق متميز يستجيب بشكل كامل
 على أي جهاز.`}
                        /> </Grid>
                        <Grid item md={3} xs={12}><Card
                            img={img4}
                            title={`تحسين محرك البحث`}
                            desc={`كفريق مختص في التسويق الرقمي 
فإننا ندرك أهمية أن يكون لديك خطة
حسين محركات البحث (SEO).
 فيما يلي بعض خطط تحسين
 محركات البحث (SEO)`}
                        /> </Grid>
                    </Grid> */}
                </Box>
            </Container>
        </>
    )
}

export default Advantages