// import { useTheme } from '@emotion/react';
import { CardMedia, Container, Grid } from '@mui/material'
import React from 'react'
import img from './../../../assets/StartNow/Group 2607.png'
import HeroText from './HeroText';


const HeroStart = () => {

    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Container maxWidth={'lg'}>
                <Grid container sx={{ alignItems: 'center',flexDirection: {md: 'row-reverse' , xs:'column'} }} >
                        <Grid item md={6} xs={12}>
                            <CardMedia
                            alt='test'
                                component={'img'}
                                src={img}
                                sx={{ width: '70%', margin: '0 auto', zIndex: '99', position: 'relative', my: 2 }}
                            />
                        </Grid>
                    <Grid item md={6} xs={12}>
                        <HeroText />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default HeroStart
