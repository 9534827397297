import { Box, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/logo.png'
import Pages from './Pages'
import Content from './Content'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
const TopFooter = () => {
    const { t } = useTranslation()
    const location = useLocation();
    let path = location.pathname.split('/')[1]
    const isBooking = path === "booking"
    return (
        <>
            <Grid container spacing={{ md: 4,xs:1 }} >
                <Grid item md={6} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems:{md:'initial',xs:'center'} }} >
                        <CardMedia
                            component={'img'}
                            src={logo}
                            sx={{ width: { md: '25%', xs: '35%' }, mt: 1 }}
                        />
                        <Typography sx={{ color: '#FFF', my: 2 }} >مزيد شركة سعودية متخصصة في التسويق الرقمى وتقنية المعلومات وجميع الخدمات المساندة للتجارة الإلكترونية.
                            نحن نسعى دائمًا لتحقيق أهداف عملائنا من خلال استراتيجيات متقدمة وفريق عمل محترف ونهدف  لفتح آفاق جديدة.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: {md:1,xs:0} }} >
                        <Typography variant={'h5'} color={'#F0AF3C'} >
                            مكتب السعودية
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            الرياض - أبحر الشمالية
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            00966554058269
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            <a href="mailto:info@mazidagency.com" style={{ textDecoration:'none', color:'#FFF' }}>
                                info@mazidagency.com
                            </a>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: {md:1,xs:0}, direction: 'ltr' }} >
                        <Typography variant={'h5'} color={'#F0AF3C'} sx={{ direction: 'ltr' }} >
                            مكتب أمريكا
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1, direction:'ltr' }} >
                            128 City Road London Ec1v2nx <br/> United States    
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            +18382054674
                        </Typography>
                        <Typography sx={{ color: '#FFF', my: 1 }} >
                            <a href="mailto:Info@drokya.com" style={{ textDecoration: 'none', color: '#FFF' }}>
                                Info@drokya.com
                            </a>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { md: 'row', xs: 'column' }, gap: { md: 0, xs: 1 }, my: 0.5, pt: 2 }} >
                        <Pages />
                        <Typography sx={{ textAlign: 'center', color: '#fff', fontSize: '1rem' }}>
                            {t("copeRight")}
                        </Typography>
                        {!isBooking && <Content />}
                    </Box>
        </>
    )
}

export default TopFooter