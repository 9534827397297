import { Container, Grid } from '@mui/material'
import React from 'react'
import ListCardServices from './ListCardServices'
import Head from './Head'
import CardService from '../glopal/CardService'
import { services } from '../../constants'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
const PopularServices = () => {
    let lng = i18next.language
    const { t } = useTranslation()
    return (
        <>
            <Container maxWidth={'lg'} >
                <Head />
                <ListCardServices>
                    {
                        services.slice(0, 3).map((service) =>
                            <Grid item md={4} xs={12} key={service?.id}>
                                <CardService
                                    color={'#8A8A8A'}
                                    img={service?.img}
                                    name={lng === 'ar' ? service?.name : service?.nameEn}
                                    price={service?.price}
                                    shop={t("PopularServices.buying")}
                                    desc={t("service.desc")}
                                    path={`/service/${service?.id}`}
                                />
                            </Grid>
                        )
                    }
                </ListCardServices>
            </Container>
        </>
    )
}

export default PopularServices