import { Box, Typography } from '@mui/material'
import React from 'react'

const ServiseDesc = ({ name, title, desc }) => {
    return (
        <>
            <Box sx={{ textAlign:{md:'inherit', xs:'center'} }} >
                <Typography variant={'h5'} sx={{ fontWeight: 'bold' }}  >{name}</Typography>
                <Typography my={1} >{title}</Typography>
                <Typography>{desc}</Typography>
            </Box>
        </>
    )
}

export default ServiseDesc
