import { Box, CardMedia, Container, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import book1 from './../../assets/Path 634.png'
import book2 from './../../assets/Group 1567.png'
import { useTheme } from '@emotion/react'
const Header = ({ home, path, children }) => {
    const { t } = useTranslation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Container maxWidth={'sm'}>
                <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', my:4 }} >
                    <Box sx={{ position:'absolute', top: 0, right:{md:'20px',xs:'0'} }} >
                        <CardMedia 
                         alt='test'
                            component={'img'}
                            src={book1}
                            sx={{ width:'30px', height:'30px', objectFit:'contain' }}
                        />
                    </Box>
                    <Box color={"secondary.main"} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Typography variant={isMobile ? 'h6' :'h5'} >{t("linksPages.home")} </Typography>
                        <Typography variant={isMobile ? 'h6' :'h5'} > - </Typography>
                        <Typography variant={isMobile ? 'h6' :'h5'} >{path}</Typography>
                    </Box>
                    {children}
                    <Box sx={{ position:'absolute', bottom: '50%', left:{md:'20px',xs:'0'} }} >
                        <CardMedia 
                         alt='test'
                            component={'img'}
                            src={book2}
                            sx={{ width:'30px', height:'30px', objectFit:'contain' }}
                        />
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Header