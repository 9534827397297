import React from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import ContactUs from '../components/ContactUs'
import { Typography } from '@mui/material'

const Contact = () => {
    const { t } = useTranslation()
    return (
        <>
            <Header path={t("linksPages.ContactUs")}>
                <Typography>
                    {t("contact.title")}
                </Typography>
            </Header>
            <ContactUs />
        </>
    )
}

export default Contact
