import { Box, CardMedia, Divider, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import ico from './../../../assets/StartNow/Icon awesome-check-double.png'
import { useTheme } from '@emotion/react';
const Item = ({ Text }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', alignItems:'baseline', gap:2  }} >
                    <CardMedia
                        alt='test'
                        component={'img'}
                        src={ico}
                        sx={{ width: '15px', height: '15px', objectFit: 'contain' }}
                    />
                    <Typography my={2} >{Text}</Typography>
                </Box>
                {isMobile && <Divider sx={{ borderColor: '#000', borderWidth: '1px', width: '50%', margin: 'auto' }} />}
            </Box>
        </>
    )
}

export default Item