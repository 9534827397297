import { useTheme } from '@emotion/react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

const YouDeserveDesc = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { md: 5 ,xs:2}, alignItems: 'flex-start' }} >
                <Typography variant={isMobile ? 'h6' :'h4'} fontWeight={'600'} sx={{ lineHeight:'1.5' }}>{t("ServicesYouDeserve.title")}</Typography>
                <Typography variant={isMobile ? 'body1' : 'h6'} >{t("ServicesYouDeserve.desc")}</Typography>
                <Box>
                    <Button 
                        onClick={() => navigate('/services')}
                    sx={{
                        backgroundColor: '#FABB25', color: '#000',
                        ':hover': {
                            backgroundColor: '#FABB25'
                        }, width:  '150px',
                        fontWeight:'700'
                    }} >{t("ServicesYouDeserve.search")}</Button>
                </Box>
            </Box>
        </>
    )
}

export default YouDeserveDesc