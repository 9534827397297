import React from 'react'
import Header from '../components/glopal/Header'
import { Box, Container, Typography } from '@mui/material'

const SiteMap = () => {
    return (
        <>
            <Header path={'- خريطة الموقع:- '} />
            <Container maxWidth={'md'} >
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: 4, py: 4,
                    justifyContent: 'space-evenly',
                    '@media (min-width: 1440px)': {
                        height: '50rem',
                    }
                }}>
                    <Typography textAlign={'center'} >نلتزم في مؤسسة Mazid بتقديم خدمات عالية الجودة مع ضمان رضا العملاء نؤكد على أهمية تسليم المشاريع بشكل كامل ومنجز وفقًا للاتفاق المسبق. </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant='h5' >
                            اتفاقيات واضحة:
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }} >عندما يتم توظيف فريلانسر لإكمال مشروع محدد، فإن التفاصيل والمهام المطلوبة تُحدد بوضوح في اتفاقية العمل بين العميل والفريلانسر حيث يتم تحديد المهام المطلوبة والوقت المتاح لإنجازها.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5' >
                            الالتزام بالوقت والجودة:
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }} >نحن نؤمن بأهمية الالتزام بالوقت المحدد المتفق عليه بين العميل والفريلانسر مع ضمان أعلى جودة للعمل المطلوب تسليمه.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5' >
                            ضمان التسليم بعد إنجاز العمل:
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }} >نضمن أنه لن يتم تسليم حساب المشروع بالكامل للفريلانسر إلا بعد إنجاز المهام المطلوبة وفقًا للاتفاق بين العميل والفريلانسر.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5' >
                            جمع البيانات الشخصية:
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }} >
                            نقوم بجمع البيانات الشخصية المقدمة منك بموافقتك الصريحة عند التسجيل أو استخدام خدماتنا مثل الاسم، العنوان، عنوان البريد الإلكتروني، رقم الهاتف، ومعلومات أخرى ذات صلة نستخدم هذه المعلومات لتقديم الخدمات وتحسين تجربتك معنا.
                            نحتفظ بسرية البيانات الشخصية ولا نقوم ببيعها أو تأجيرها أو مشاركتها مع أطراف ثالثة غير معتمدة
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default SiteMap