import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { faqs } from '../../constants';
import { useState } from 'react';
import i18next from 'i18next';

export default function BasicAccordion() {
    let lng = i18next.language
    const [expandedId, setExpandedId] = useState(1);  

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedId(isExpanded ? panel : null);
    };
    return (
        <>
            {
                faqs.map((faq) =>
                    <React.Fragment key={faq.id}>
                        <Accordion key={faq.id}
                            defaultExpanded={faq.id === 1}
                            expanded={faq.id === expandedId}
                            onChange={handleChange(faq.id)}
                            sx={{ my: '16px' }}
                        >
                            <AccordionSummary sx={{
                                backgroundColor: faq.id === expandedId ? '#4C164C' : "#fff",
                                color: faq.id === expandedId ? '#fff' : "#4C164C",

                            }}
                                expandIcon={<ExpandMoreIcon sx={{ color: faq.id === expandedId ? '#fff' : "#4C164C", }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography>{lng === 'en' ? faq.title : faq.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography textAlign={'right'} sx={{ fontSize:'14px' }} >
                                    {lng === 'en' ? faq.desc : faq.desc}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </React.Fragment>
                )
            }
        </>
    );
}
