import React, { useEffect, useState } from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import { Box, Container, Grid } from '@mui/material'
import ListCardServices from '../components/PopularServices/ListCardServices'
import { services } from '../constants'
import CardService from '../components/glopal/CardService'
import Transfers from '../components/glopal/Transfers'
import i18next from 'i18next'
import Filter from '../components/Filter'

const Services = () => {
    let lng = i18next.language
    const { t } = useTranslation()
    const [idService, setIdService] = useState(0)
    const [Service, setService] = useState(idService);

    useEffect(() => {
        const foundService = services.find((Service) => Service.id === Number(idService));
        setService(foundService);
    }, [idService, Service]); 
    return (
        <>
            <Header path={t("linksPages.OurServices")} >
                <Filter Service={Service} setIdService={setIdService} />
            </Header>
            <Container maxWidth={'lg'}>
                <Box>
                    <ListCardServices>
                        {
                            idService === 0 ?
                                services.map((service) =>
                                    <Grid item md={4} xs={12} key={service?.id}>
                                        <CardService
                                            color={'#8A8A8A'}
                                            img={service?.img}
                                            name={lng === 'en' ? service?.nameEn : service?.name}
                                            price={service?.price}
                                            shop={t("PopularServices.buying")}
                                            desc={t("service.desc")}
                                            path={`/service/${service?.id}`}
                                        />
                                    </Grid>
                                )
                                :
                                <Grid item md={4} xs={12} key={Service?.id}>
                                    <CardService
                                        color={'#8A8A8A'}
                                        img={Service?.img}
                                        name={lng === 'en' ? Service?.nameEn : Service?.name}
                                        price={Service?.price}
                                        shop={t("PopularServices.buying")}
                                        desc={t("service.desc")}
                                        path={`/service/${Service?.id}`}
                                    />
                                </Grid>
                        }
                    </ListCardServices>
                </Box>
            </Container>
            {/* <Transfers btn={t("transfer.btn")} /> */}
        </>
    )
}

export default Services