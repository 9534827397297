import { Box, CardMedia, Container, useMediaQuery } from '@mui/material'
import React from 'react'
import img from './../../assets/skills/OBJECTS.svg'
import DescSkills from './DescSkills'
import './skills.css'
import { useTheme } from '@emotion/react'
const Skills = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <div className="gradient-skills">
                <Container maxWidth={'lg'} >
                    <Box sx={{ display:"flex", flexWrap:'wrap', justifyContent:'center', alignItems:'center' }} >
                        <Box sx={{ width:{md:'50%',xs:'100%'} }}>
                            <DescSkills/>
                        </Box>
                        {
                            !isMobile &&
                        <Box sx={{ width:{md:'50%',xs:'100%'} }}>
                            <CardMedia
                                        alt='test'
                                component={'img'}
                                src={img}
                                sx={{ width: '75%', margin: '0 auto', mr:'-5%' }}
                            />
                        </Box>
                        }
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default Skills