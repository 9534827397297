import React from 'react';
import { Box, CardMedia, Container, Typography, useMediaQuery } from '@mui/material';
import Marquee from "react-marquee-slider";
import { useTranslation } from 'react-i18next';
import { logos } from '../../constants';
import './style.css'
import { useTheme } from '@emotion/react';
const Projects = () => {
    const { t } = useTranslation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box my={4} >
                    <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                        <Typography mb={2} variant='h4' > <span style={{ color: '#FABB25' }} >{t('SuccessPartners.title')}</span> 
                        {t('SuccessPartners.supTitle')}</Typography> 
                        <Typography color={'#8A8A8A'} mb={2} variant='h6' >{t('SuccessPartners.Desc')}</Typography> 
                    </Box>
                    <Marquee velocity={isMobile ? 2 :8}
                        radius={50}  direction='rtl'>
                        {logos.map((image, index) => (
                            <Box sx={{ border: '1px solid #CCE1E5', background:'#e5e3e3', borderRadius:'8px', mx:2,
                            my:1,
                            ':hover':{ 
                                background:'#CCE1E5', 
                                boxShadow:'0px 4px 46.400001525878906px rgba(0, 0, 0, 0.10)',
                                transition: '0.5s'
                            } }} key={index} className={'parent'} >
                                <CardMedia
                                    component={'img'}
                                    key={index}
                                    src={image?.img}
                                    alt={`Slide ${index + 1}`}
                                    />
                            </Box>
                        ))}
                    </Marquee>
                    <Marquee velocity={isMobile ? 2 :8}
                        radius={50}  direction='ltr'>
                        {logos.map((image, index) => (
                            <Box sx={{  border: '1px solid #CCE1E5', background:'#e5e3e3', borderRadius:'8px', mx:2,
                            my:1,
                            ':hover':{ 
                                background:'#CCE1E5', 
                                boxShadow:'0px 4px 46.400001525878906px rgba(0, 0, 0, 0.10)',
                                transition: '0.5s'
                                }
                            }} key={index} className={'parent'}>
                                <CardMedia
                                    component={'img'}
                                    key={index}
                                    src={image?.img}
                                    alt={`Slide ${index + 1}`}
                                    />
                            </Box>
                        ))}
                    </Marquee>
                </Box>
            </Container>
        </>
    )
}

export default Projects