import React from 'react'
import Item from './Item'
// import { ListPackages } from '../../../constants'
import './style.css'
const ListItem = ({ ListPackages }) => {
    return (
        <>
            <ul style={{ listStyle:'none', padding:0 }}  >
                {ListPackages?.map((item) => <li key={item?.id} ><Item Text={item?.Text} /></li>)}
            </ul>
        </>
    )
}

export default ListItem