
import servS1 from './../assets/services/Group 2074.png'
import servS2 from './../assets/services/Group 1695.png'
import servS3 from './../assets/services/Group 1680.png'
import servS4 from './../assets/services/serv4.png'
import servS5 from './../assets/services/serv4.png'
import servS6 from './../assets/services/serv6.png'
import servS7 from './../assets/services/SERV7.png'
import servS8 from './../assets/services/serv8.png'
import servS9 from './../assets/services/serv9.png'

import p1 from './../assets/Work/brog/1.jpeg'
import p2 from './../assets/Work/brog/2.jpeg'
import p3 from './../assets/Work/brog/3.jpeg'
import p4 from './../assets/Work/brog/4.jpeg'

import de1 from './../assets/Work/desiyn/extra.png'
import de2 from './../assets/Work/desiyn/ghost.png'
import de3 from './../assets/Work/desiyn/IMG-20231123-WA0012.jpg'
import de4 from './../assets/Work/desiyn/IMG-20231217-WA0026.jpg'
import de5 from './../assets/Work/desiyn/IMG-20231220-WA0019.jpg'
import de6 from './../assets/Work/desiyn/IMG-20240218-WA0029.jpg'
import de7 from './../assets/Work/desiyn/IMG-20240218-WA0030.jpg'
import de8 from './../assets/Work/desiyn/wagbaa.png'

import part1 from './../assets/partner/Adobe-logo.png'
import part2 from './../assets/partner/Meta-Logo.png'
import part3 from './../assets/partner/react-logo-65B7CD91B5-seeklogo.com.png'
import part4 from './../assets/partner/Shopify_Logo.png'
import part5 from './../assets/partner/youtube-logo-11609383902z56yosfap9.png'
import ll1 from './../assets/slider/l1.png'
import ll2 from './../assets/slider/l2.png'
import ll3 from './../assets/slider/l3.png'
import ll4 from './../assets/slider/l4.png'
import ll5 from './../assets/slider/l5.png'
import ll6 from './../assets/slider/l6.png'
import ll7 from './../assets/slider/l7.png'
import ll8 from './../assets/slider/l8.png'
import l4 from './../assets/slider/4.png'
import l5 from './../assets/slider/5.png'
import l6 from './../assets/slider/6.png'
import l8 from './../assets/slider/8.png'
import l10 from './../assets/slider/10.png'
import l13 from './../assets/slider/13.png'
import l14 from './../assets/slider/14.png'
import l15 from './../assets/slider/15.png'
import l17 from './../assets/slider/17.png'
import l20 from './../assets/slider/20.png'
import l23 from './../assets/slider/23.png'
import offer from './../assets/Group 1429.png'
import blog from './../assets/group-people-working-out-business-plan-office.png'
import pakge1 from './../assets/start.png'
import pakge2 from './../assets/soch.png'
import pakge3 from './../assets/deg.png'
import pakge4 from './../assets/advanced.png'
export const services = [
        {
                id: 1,
                name: `برمجة المواقع`,
                nameEn: `Website programming.`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS4,
                title: "العملية التي تتضمن كتابة وتنفيذ الشفرة البرمجية",
                desc: "لبناء و تطوير مواقع الويب.  وتعتبر أهم عملية لظهور الموقع للمستخدم بشكل بتناسب مع استخداماته بشكل متكامل.                         لذلك ",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: ' موقع تفاعلي ومبتكر مصمم لتقديم أفضل تجربة للمستخدم',
                        item2: 'استضافة متقدمة',
                        item3: 'دعم فني مستمر.',
                },
                titleEn: "The process that includes writing and implementing ",
                descEn: `software code to build and develop websites.
 It is considered the most important process for the site to appear to the user in a way that is consistent with its uses in an integrated manner.
`,
                listEn: {
                        item1: `An interactive and innovative website designed to provide the best user experience`,
                        item2: `Advanced hosting`,
                        item3: `Ongoing technical support.`,
                },
                article: {
                        title: `( برمجة المواقع خطوة لا غني عنها)`,
                        desc: `تزداد أهمية المواقع يوما بعد يوم خصوصاً للأنشطة التجارية لدرجة أنها أصبحت لا تقل أهمية عن أى خطوة من خطوات العمل ذاته ، فإن كنت لا تمتلك مواقع فأنت تفقد فرص لا حصر لها لتعزيز مبيعاتك و تنمية نشاطك التجارى`,
                        because: ` -  أهمية برمجة المواقع ؟`,
                        list: [
                                {
                                        title: `١-تشبه برمجة المواقع امتلاكك لفريق عمل على مدار الساعة.`,
                                        desc: ``
                                },
                                {
                                        title: ` ٢-قدرة عملائك على تصفح الموقع فى أى وقت وبأى مكان ، و معرفة طبيعة نشاطك التجارى وما تقدمة من خدمات أو منتجات.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- معرفة طرق التواصل ، و إتمام عمليات الشراء المباشر كما يعزز الوعى بعلامتك التجارية و زيادة مبيعاتك.`,
                                        desc: ``
                                },
                                {
                                        title: `٤- الوصول إلى نطاق أوسع من العملاء.`,
                                        desc: ``
                                },
                        ],
                        because2: `- كيفية برمجة موقع إلكتروني احترافي؟`,
                        list2: [
                                {
                                        title: `عند برمجة المواقع ضروري تحرص علي :- `,
                                        desc: `-سهولة الإطلاق، وتقديم تجربة مستخدم مميزة.`
                                },
                                {
                                        title: ``,
                                        desc: `-عدم وجود أخطاء في التصميم أو التفاعل.`
                                },
                                {
                                        title: ``,
                                        desc: `-سرعة التصفح.`
                                },
                                {
                                        title: ``,
                                        desc: `-تجميع المعلومات الأولية عن الموقع المراد برمجته.`
                                },
                                {
                                        title: ``,
                                        desc: `-البدء بإعداد محتوى الموقع بالتوازي مع مرحلة البرمجة.`
                                },
                                {
                                        title: ``,
                                        desc: `-اختبار الموقع لضمان خلوّه من الأخطاء.`
                                },
                        ],
                        because3: ` - كما تتضمن عملية البرمجة  كتابة و تنفيذ الشفرة البرمجية لبناء و تطوير مواقع الويب و تعتبر أهم عملية لظهور الموقع للمستخدم بشكل يتناسب مع استخداماته بشكل متكامل لذلك نقدم لك :-`,
                        list3: [
                                {
                                        title: `   • موقع تفاعلى و مبتكر مصمم لتقديم أفضل تجربة للمستخدم.`,
                                        desc: ``
                                },
                                {
                                        title: `   • استضافة متقدمة .`,
                                        desc: ``
                                },
                                {
                                        title: `   • دعم فنى مستمر. `,
                                        desc: ``
                                },
                        ],
                        because4: `  إحدى منصات مواقع الويب الشهيرة هى WordPress  وهي تعمل على شراء خدمة استضافة موقع الويب و إتاحة الموقع للجمهور، وذلك لما لها من فوائد أساسية فى :-`,
                        list4: [
                                {
                                        title: ` • سهولة الإستخدام : حيث إن واجهته قائمة على قوائم سهلة الاستخدام للمستخدمين من أى مستوى مهارة .`,
                                        desc: ``
                                },
                                {
                                        title: ` • متعدد الاستخدامات للغاية :  تتيح مجموعة المكونات الإضافية الشاملة فئة إضافة وظائف مخصصة أعلى البرنامج الأساسى وبذلك يمكنك برمجة المواقع ببضع نقرات .`,
                                        desc: ``
                                },
                                {
                                        title: ` • القابلية للتطوير: نظراً لأن المستخدمين يتحكمون فى استضافة الويب الخاصة بهم ، يمكنهم اختيار ترقية خطتهم عندما يحتاجون إلى المزيد من الموارد.`,
                                        desc: ``
                                },
                                {
                                        title: `  نعمل على استخدام كافة الوسائل والطرق التى تجعل موقعك سهل وبسيط لدى عملائك و نوفر لك فى التكلفة .`,
                                        desc: ``
                                },
                        ],
                        because5: `      • أهم أهداف برمجة المواقع : `,
                        list5: [
                                {
                                        title: `١- إنشاء مواقع ويب أمنه وسريعة ومتوافقة مع متصفحات الويب المختلفة`,
                                        desc: ``
                                },
                                {
                                        title: `٢- تحسين تجربة المستخدم و توفير الوظائف التى يحتاجها.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- تحسين عمليات التسويق الإلكتروني وجذب المزيد من العملاء .`,
                                        desc: ``
                                },
                                {
                                        title: `٤- تحسين عمليات الإدارة و التحكم بالمحتوى والبيانات.`,
                                        desc: ``
                                },
                                {
                                        title: `٥- توفير خدمات الدعم الفني و الصيانة للمواقع`,
                                        desc: `حيث أصبحت برمجة المواقع جزء لا يتجزأ من نجاح الموقع و تلعب دوراً حيوياً فى توفير الخدمات والمعلومات للمستخدمين وتحسين تجربتهم على الويب.`
                                },
                                {
                                        title: ``,
                                        desc: `ختاما ، أظهر احترافيتك فى العمل و عزز ثقة العملاء فى نشاطك التجارى و انتشر بشكل كبير وادعم موقعك بكافة طرق التطور والبرمجه كى تظل دائما فى تقدم .
عليك الآن البدء في  البرمجة الخاصة بموقعك`
                                },
                        ],
                },
                pakges: {
                        title: "عروض برمجة المواقع الالكترونية",
                        list: [
                                {
                                        id: 1,
                                        img: pakge1,
                                        list: [
                                                "تصميم موقع الكتروني محدد",
                                                "لوحة تحكم للموقع",
                                                "متوافق مع الموبايل",
                                                "عدد 5 صفحات",
                                                "تصميم موقع لغة واحدة",
                                                "استضافة لمده سنه",
                                                "دومين لمدة سنه",
                                                "انشاء 2 ايميل رسمي",
                                                "انشاء حسابات علي السوشيال",
                                                "لا يوجد صناعة محتوي",]
                                },
                                {
                                        id: 2,
                                        img: pakge2,
                                        list: [
                                                "تصميم موقع الكتروني محدد",
                                                "لوحة تحكم للموقع",
                                                "متوافق مع الموبايل",
                                                "عدد 7 صفحات",
                                                "تصميم موقع لغة واحدة",
                                                "استضافة لمده سنه",
                                                "دومين لمدة سنه",
                                                "انشاء 10 ايميلات بحد اقصي",
                                                "انشاء حسابات علي السوشيال",
                                                "تهيئة محركات البحث SEO",
                                                "دعم فني لمدة سنة",
                                                "لا يوجد صناعة محتوي",]
                                },
                                {
                                        id: 3,
                                        img: pakge3,
                                        list: [
                                                "تصميم موقع الكتروني محدد",
                                                "لوحة تحكم للموقع",
                                                "متوافق مع الموبايل",
                                                "عدد 10 صفحات",
                                                "تصميم الموقع 2 لغة",
                                                "استضافة لمده سنه",
                                                "دومين لمدة سنه",
                                                "انشاء 25 ايميل بحد اقصي",
                                                "انشاء حسابات علي السوشيال",
                                                "تصميم حسابات السوشيال ميديا",
                                                "تهيئة محركات البحث SEO",
                                                "دعم فني وصيانه دورية للموقع",
                                                "صناعة محتوي نصي للموقع",]
                                },
                                {
                                        id: 4,
                                        img: pakge4,
                                        list: [
                                                "تصميم متجر الالكتروني",
                                                "لوحة تحكم للمتجر",
                                                "متوافق مع الموبايل",
                                                "عدد 15 صفحة بحد اقصي",
                                                "تصميم الموقع 3 لغات",
                                                "استضافة لمده سنه",
                                                "دومين لمدة سنه",
                                                "انشاء عدد لانهائي من الايميلات",
                                                "انشاء حسابات علي السوشيال",
                                                "تصميم حسابات السوشيال ميديا",
                                                "تهيئة محركات البحث SEO",
                                                "دعم فني وصيانه دورية للموقع",
                                                "صناعة محتوي نصي للموقع",]
                                },
                        ]
                }
        },
        {
                id: 2,
                name: `تصميم موبايل ابليكشن`,
                nameEn: `Mobile application design.`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS7,
                title: "تصميم تطبيق للهاتف يسهل علي عملائك عملية الشراء",
                desc: "ويساعدك تعرض منتجاتك علي مدار ال 24 ساعة. ولإن معظم المستهلكين يقضوا أغلب وقتهم علي الهواتف سيساعدك تحقق ارتباط قوي بينك وبين مستهلكينك.",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'توسيع دائرة استهدافك ووصلك لعملاء جدد بغض النظر عن المسافة.',
                        item2: 'تحقبق تجربة تسوق جديدة ومميزة وتقدبم من خلالها كل جديد.',
                        item3: 'مراعاة المعايير الواجب توافرها في ال mobile App الخاص بك من  كفاءة،سرعة،تميز".',
                },
                titleEn: "Designing a phone application that facilitates the purchasing process",
                descEn: ` for your customers and helps you display your products 24 hours a day.
 Because most consumers spend most of their time on phones, it will help you achieve a strong connection between you and your consumers.
 Therefore`,
                listEn: {
                        item1: ` Expand your targeting circle and reach new customers, regardless of distance.`,
                        item2: `Achieve a new and distinctive shopping experience and offer everything new through it.`,
                        item3: `Taking into account the standards that must be met in your mobile application, such as efficiency, speed, and excellence.`,
                },
                article: {
                        title: `( الموبايل أبلكيشن مقصد عملائك الدائم)`,
                        desc: `بينما يزداد الطلب يوماً بعد يوم على تصميم الموبايل أبلكيشن لنبقى فى نجاح مستمر وتطور دائم .
 نجد سؤال مهم يطرح نفسه ماذا نعنى بتصميم الموبايل أبلكيشن ؟
هو عبارة عن عملية إبداعية وتقنية تهدف إلى إنشاء تجربة مستخدم جذابة وسهلة الاستخدام على الهواتف الذكية والأجهزة اللوحية، 
و يشير إلى كل ما يشاهده و يتفاعل معه المستخدم داخل التطبيق سواء كان اندرويد أو ios .
 -نهدف من خلاله التسهيل على عملائك عملية الشراء ، ومساعدتك في عرض منتجاتك على مدار ال24 ساعة.
`,
                        because: `    -  أهمية تطبيقات الموبايل أبلكيشن لأصحاب الأعمال ؟`,
                        list: [
                                {
                                        title: `١- يمكن لهذة التطبيقات مساعدة الشركة بتنفيذ الكثير من أعمالها الداخلية بسهولة وبساطة `,
                                        desc: ``
                                },
                                {
                                        title: ` ٢- تعتبر تكلفة إنجاز التطبيق أقل نسبياً من تنفيذ البرمجيات الكاملة التى يتم تحميلها على الأجهزة الخاصة.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- تصل الشركة وخدماتها المحددة لكافة الأفراد على هواتفهم المحمولة.`,
                                        desc: ``
                                },
                                {
                                        title: `٤- توصل العملاء مع الشركة بالشكل الصحيح للحصول على أفضل خدمة وفقاً للمعايير المرغوبة.`,
                                        desc: ``
                                },
                                {
                                        title: `٥- تعمل على توسيع العمل من خلال وصل الموظفين مع الشركة خارج النطاق المحدد للشركة.`,
                                        desc: ``
                                },
                                {
                                        title: `٦-زيادة زيارات موقعك.`,
                                        desc: ``
                                },
                                {
                                        title: `٧-دعم العملية التسويقية لمشروعك.`,
                                        desc: ``
                                },
                        ],
                        because2: `- خطوات يجب اتباعها عند تصميم موبايل أبلكيشن:- `,
                        list2: [
                                {
                                        title: `-تحديد هدف وفكرة التطبيق`,
                                        desc: ``
                                },
                                {
                                        title: `-اختيار نظام التشغيل المناسب.`,
                                        desc: ``
                                },
                                {
                                        title: `-اختيار نمط التصميم المناسب.`,
                                        desc: ``
                                },
                                {
                                        title: `-اختيار الأدوات والبرامج المناسبة للتصميم والبرمجة.`,
                                        desc: ``
                                },
                                {
                                        title: `-إنشاء نماذج سلكية ونماذج أولية.`,
                                        desc: ``
                                },
                        ],
                        because3: `  -  ولأن معظم المستهلكين  يقضوا أغلب أوقاتهم على الهواتف سيساعدك هذا في :- `,
                        list3: [
                                {
                                        title: ` -تحقيق ارتباط قوى بينك وبين مستهلكينك.`,
                                        desc: ``
                                },
                                {
                                        title: ` -توسيع دائرة استهدافك و وصولك لعملاء جدد بغض النظر عن المسافة.`,
                                        desc: ``
                                },
                                {
                                        title: `  -تحقيق تجربة تسوق جديدة و مميزة و تقدم من خلالها كل جديد.`,
                                        desc: ``
                                },
                                {
                                        title: `•كل هذا مع مراعاة المعايير الواجب توافرها فى ال mobile App الخاص بك من كفاءة ، سرعة ، تميز . `,
                                        desc: ``
                                },
                                {
                                        title: `    • يجيب تحديد الهدف من تصميم الموبايل ابلكيشن. `,
                                        desc: `سيساهم تحديد الهدف من إنشاء تطبيق الموبايل فى معرفة طبيعة المنتجات و الخدمات التى سيتم تقديمها من خلاله ، وبالتالي سيكون تحديد الفئه المستهدفة أسهل ، و تحديد نوع الخدمات و الدعايه والاعلان المناسب و تحقيق الربح المطلوب `
                                },
                        ],
                        because4: `     أهمية تصميم الموبايل ابلكيشن بصفة عامة ؟ `,
                        list4: [
                                {
                                        title: ` ١- وجود تصميم ابلكيشن عالى الكفاءة يؤدى إلى تفاعل كبير من المستخدمين وهو ما يمنح علامتك التجارية المزيد من الثقة لدى العملاء .`,
                                        desc: ``
                                },
                                {
                                        title: `٢- يساهم فى عرض خدماتك و منتجاتك على أكبر قدر من العملاء فى أسرع وقت فى أماكن مختلفة بتكلفة أقل بكثير من الدعاية التقليدية.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- توفير عنصر الأمن للمستخدم ، فهو غير مضطر للتجول وهو يحمل أموال لشراء منتجات أو الحصول على خدمات ، بل يمكنه الدفع عن طريق الإنترنت و الدفع عند الاستلام`,
                                        desc: ``
                                },
                                {
                                        title: `٤- يمكن العملاء من شراء المنتجات والخدمات بكل سهولة بدون الحاجة إلى مغادرة أماكنهم والتغلب على الظروف التى قد تعوق الحركة و التنقل وهو ما يزيد من نسب البيع.`,
                                        desc: ``
                                },
                                {
                                        title: `٥- يمحنك خبرة كبيرة فى التعرف على توجهات السوق و طريقة تفكير العملاء عن طريق التفاعل و التعليقات و الأسئلة التى يطرحونها وبالتالى تتمكن من تطور منتجاتك و خدماتك لتتوافق مع متطلبات السوق .`,
                                        desc: ``
                                },
                                {
                                        title: `٦- يعطى تصميم تطبيق موبايل أفضلية لموقعك الالكتروني فعن طريق التطبيق سيزيد زوار الموقع و بالتالى يمكنك الإستفادة منه بشكل كبير. `,
                                        desc: ``
                                },
                        ],
                        because5: `     ★نظراً لكبر حجم السوق والمنافسة عليك تحليل تطبيقات المنافسين لأنه يلعب دوراً حاسماً فى نجاح تطبيقك وذلك مع شركتك التسويقية من خلال تنفيذ الخطوات التالية: `,
                        list5: [
                                {
                                        title: `    • حدد التطبيقات الرئيسية التى تتنافس مع تطبيقك واستكشاف نماذجها التجارية و ميزاتها.`,
                                        desc: ``
                                },
                                {
                                        title: `  • تحليل الخصائص المميزة فى تطبيقات المنافسين ، سواء كانت فى التصميم ، الأداء ، أو تجربة المستخدم.`,
                                        desc: ``
                                },
                                {
                                        title: `  • قيم التصميم المرئى لتطبيقات المنافسين ، مثل إستخدام الألوان ، و تنظيم العناصر ، و جاذبية الواجهة.`,
                                        desc: ``
                                },
                                {
                                        title: `• انظر كيفية تفاعل المستخدم مع تطبيقات المنافسين ، وهل هناك تجارب فريدة تستحق التطوير ؟`,
                                        desc: ``
                                },
                                {
                                        title: `  • حدد نقاط القوة و الضعف فى تطبيقات المنافسين ، وركز على كيف يمكن تحسين تجربة المستخدم فى تطبيقك.- توفير خدمات الدعم الفني و الصيانة للمواقع`,
                                        desc: ``
                                },
                                {
                                        title: `• استلهم من النقاط الإيجابية و ابتكر لتحسين التجربة فى تطبيقك ، و اتخذ خطوات فعالة للتفوق و التميز .`,
                                        desc: ``
                                },
                                {
                                        title: `    ونحن نعمل جاهدين على فحص وتحليل تلك النقاط حتى نصل معك إلى أبلكيشن يفوق المنافسة.`,
                                        desc: `    و ختاماً ؛ يعد حالياً الموبايل أبلكيشن واجهة المستخدم الأولى و الأساسية و تؤثر على نجاحك فعليك بتنفيذ واجهة رائعة و هيكلة دقيقة.`
                                },
                        ],
                }
        },
        {
                id: 3,
                name: `التصميم`,
                nameEn: `Design`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS1,
                title: " هو  فن الاتصال المرئي الذي يساعد على توصيل رسائل الشركات إلى الفئات المستهدفة،",
                desc: "حيث يعمل على نقل أفكار ورسائل بشكل مرئي ويتم استخدامه للترويج عن المنتجات والخدمات، كما يتضمن العديد من العناصر منها اللون، الطباعة، الشكل، الخط، الملمس.",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'تصميمات حديثة ومبتكرة تجذب انتباه العملاء. ',
                        item2: 'بناء مصداقية ونقل صورة قوية عنك.',
                        item3: 'تكوين انطباع إيجابي لدي عملائك.',
                },
                titleEn: "It is the art of visual communication that helps deliver corporate",
                descEn: ` messages to target groups. It conveys ideas and messages visually and is used to promote products and services. It also includes many elements, including color, typography, shape, font, and texture.
`,
                listEn: {
                        item1: `Modern and innovative designs that attract customers' attention.`,
                        item2: `Build credibility and convey a strong image of yourself.`,
                        item3: `Create a positive impression on your customers.`,
                },
                article: {
                        title: `التصميم ( فن الاتصال المرئى ).`,
                        desc: `
هو نقطة التقاء الوظيفة بالشكل لإنه أحد العوامل الرئيسية التي تجذب المستهلكين إلى منتج ما، أو تدفعهم إلى تفضيل استخدام منتج على آخر.
يعتبر من الفنون المبدعة التى تجمع بين الجمال والوظيفة، حيث يهدفان إلى نقل الرسائل والأفكار بشكل فعّال حيث يساهم في بناء هوية تجارية تترك انطباعًا قويًا ومستدامًا.
`,
                        because: `- تكمن أهمية التصميم في الأتى :- `,
                        list: [
                                {
                                        title: `- نقل الرسالة:`,
                                        desc: `التصميم يعتبر وسيلة فعالة لنقل الرسائل والقصص ويساعد في تجسيد الفكرة بشكل بصري يلامس العواطف ويثير التفاعل.`
                                },
                                {
                                        title: `- بناء الهوية:`,
                                        desc: ` تصاميم العلامات التجارية والشعارات تلعب دورًا أساسيًا في بناء هوية الشركة لأن العناصر المرئية تمنح العلامة التجارية هوية مميزة وتعزز التميز في السوق.`
                                },
                                {
                                        title: `- تفاعل المستخدم:`,
                                        desc: `تأثير تصميم الواجهة وتجربة المستخدم يؤثران على كيفية تفاعل الأفراد مع المواقع والتطبيقات، مما يجعل التصميم لا غنى عنه في عصر الرقمنة.`
                                },
                                {
                                        title: `- تسهيل فهم المعلومات:`,
                                        desc: `التصميم الجيد يساعد في تسهيل فهم المعلومات، سواء كانت بيانات، إحصائيات، أو فكرة معقدة. يقدم التصميم بصورة بصرية تجعل الرسالة أكثر إيضاحاً وفهمًا.`
                                },
                                {
                                        title: `- الابتكار والإلهام:`,
                                        desc: `كفن، يمكن للتصميم أن يلهم ويشجع على الابتكار مما يساهم في إثارة انتباه الجمهور وخلق تجارب جديدة ومثيرة.`
                                },
                                {
                                        title: `- كيف يساعد التصميم الجرافيكي الشركات في التسويق؟`,
                                        desc: `يستخدم التصميم الجرافيكي للترويج عن العلامات التجارية بصورة مرئية، فهو حجر الزاوية في التسويق في ظل العصر الرقمي، كما يساعد علي لفت الإنتباه للمنتجات، وتوليد عملاء محتملين، وزيادة حجم المبيعات.`
                                },
                                {
                                        title: `- يحتوى التصميم علي مجموعة من العناصر التى لا تقدر بثمن ولا غنى عنها منها:-`,
                                        desc: `الألوان، والخطوط والشكل والملمس والتفاعل وكلها عناصر تضيف عمقًا إلى التصميم وتعزز تفاعل المشاهدين.`
                                },
                                {
                                        title: `- أنواع التصميم:- `,
                                        desc: `- تصميم المنتجات. - تصميم العلامات التجارية. - تصميم الرسوم المتحركة.`
                                },
                                {
                                        title: ``,
                                        desc: `- وختاما تستحوذ التصميمات والفنون المرئية على مكانة رفيعة في عالم الأعمال الحديث، حيث تلعب دورًا حيويًا في تشكيل الانطباعات وبناء علاقات قوية مع الجمهور لذلك ضروري أن يكون  التصميم جزءاً من أي استراتيجية تجارية منذ بداية أي مشروع.`
                                },
                        ]
                }
        },
        {

                id: 4,
                name: `إدارة المنصات`,
                nameEn: ` Platform management.`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS3,
                title: "تتلخص إدارة المنصات في متابعة النشر في أوقات مناسبة لجمهورك",
                desc: "وإعداد خطط تنافسية يتم العمل بها ومتابعة وتحليل السوق المنافس وتتبع خطاه. وحتي نحقق أفضل عائد وهوية احترافية لمشروعك",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'تحليل البيانات والإحصائيات وغيرها. ',
                        item2: 'إدارة كاملة لصفحات التواصل الاجتماعي.',
                        item3: 'وضع خطة تسويقية',
                        item4: 'كتابة محتوى جذاب ومميز.',
                        item5: 'زيادة المتابعين',
                        item6: 'خدمة العملاء والتفاعل والرد على التعليقات.',
                },
                titleEn: "Platform management boils down to monitoring publishing ",
                descEn: `at appropriate times for your audience, preparing competitive plans to be implemented, following up and analyzing the competing market, and following its steps.
 In order to achieve the best return and professional identity for your project`,
                listEn: {
                        item1: 'Data analysis, statistics, etc.',
                        item2: 'Complete management of social media pages.',
                        item3: 'Develop a marketing plan',
                        item4: ` Writing attractive and distinctive content.`,
                        item5: `increase followers`,
                        item6: `Customer service, interaction, and responding to comments.`,
                },
                article: {
                        title: `إدارة المنصات ( عملية الإشراف والتحكم والتواصل ).
`,
                        desc: `عبارة عن عملية يتم فيها الإشراف على كل ما يخص صفحات منصات التواصل من تفاصيل، متضمنة تطوير الخطط والاستراتيجيات من أجل التواصل مع المتابعين.
ولهذا أصبحت إدارة حسابات التواصل الاجتماعي غير مقتصرة على تقديم المحتوى فقط، بل تخطت ذلك إلى الاهتمام بكل ما يخص الصفحة
`,
                        because: `- ماهي أهمية إدارة المنصات ؟!`,
                        list: [
                                {
                                        title: `١- ضمان فعالية التواصل مع الجمهور، والاستجابة لحاجاتهم.`,
                                        desc: ``
                                },
                                {
                                        title: `٢- معرفة الإيجابيات والسلبيات عن شركتك من خلال مشاركة الجمهور بآرائهم.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- نظرًا لإتاحة تلك الشبكات دائمًا،هذا يساعد في التواصل مع الجمهور ومشاركة المعلومات. `,
                                        desc: ``
                                },
                                {
                                        title: `٤- تستطيع من خلال الإدارة الصحيحة أن تختار القنوات التي تريدها، وكم مرة ستنشر فيها، ومساعدتك في وضع استراتيجية تسويق مُلهمة وجذابة.`,
                                        desc: ``
                                },
                        ],
                        because2: `- ماهي مهارات إدارة المنصات؟!.`,
                        list2: [
                                {
                                        title: `١- عمل خطة للمشروع.`,
                                        desc: ``
                                },
                                {
                                        title: `٢- تهيئة وتأمين حسابات التواصل الاجتماعي.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- التسويق للخدمات.`,
                                        desc: ``
                                },
                                {
                                        title: `٤- دعم وخدمة العملاء. `,
                                        desc: ``
                                },
                                {
                                        title: `٥- كتابة محتوي جذاب وعمل تحليل للبيانات.`,
                                        desc: ``
                                },
                        ],
                        because3: `- كيفية إعداد خطة لإدارة المنصات الخاصة بشركتك؟!`,
                        list3: [
                                {
                                        title: ``,
                                        desc: `يوجد عدة خطوات أساسية تمكنك من إعداد إدارة حسابات التواصل الاجتماعي لشركتك بشكل احترافي، ومن أهم هذه الخطوات:-`
                                },
                                {
                                        title: `١- حدد الهدف ويكون قابل للقياس والتحقيق.`,
                                        desc: ``
                                },
                                {
                                        title: `٢- استعن بالمقاييس التي تستند إلى قيمة حقيقية.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- حدد فئة الجمهور المستهدف`,
                                        desc: ``
                                },
                                {
                                        title: `٤- اختر منصات التواصل الاجتماعي التي تناسبك وذلك طبقًا لنوعية جمهورك. `,
                                        desc: ``
                                },
                                {
                                        title: `٥- إدارة محتوى حسابات التواصل الاجتماعي والاعتماد علي التنويع والرد علي استفسارات العملاء.`,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `
بتنفيذ استراتيجيات إدارة المنصات بشكل متقن، يمكن للمؤسسات تعزيز تواصلها وتحقيق نجاح دائم في العالم الرقمي المتطور.`
                                },
                        ],
                },
                pakges: {
                        title: "أسعار إدارة صفحات السوشيال ميديا",
                        list: [{
                                id: 1,
                                img: pakge1,
                                list: [
                                        "إدارة: 1 منصة من اختيارك",
                                        "عدد المنشورات 10 شهريا",
                                        "تصميم المحتوي 10 شهريا",
                                        " كتابة 10 محتوي ابداعي",
                                        "دراسة المنافسين : لا يوجد",
                                        "الرد علي تعليقات والرسائل",
                                        "انشاء وإدارة حملة إعلانية مجانا",
                                        "مدة الإدارة : شهر",
                                        "تقارير : أسبوعيا",]
                        },
                        {
                                id: 2,
                                img: pakge2,
                                list: [
                                        "إدارة: '2 منصة من اختيارك'",
                                        "عدد المنشورات: '15 شهريًا'",
                                        "تصميم المحتوى: '15 شهريًا'",
                                        "كتابة المحتوى الإبداعي: '15 محتوى'",
                                        "دراسة المنافسين: 'لا يوجد'",
                                        "الرد على التعليقات والرسائل",
                                        "إنشاء حملة إعلانية: '$50'",
                                        "مدة الإدارة: 'شهر'",
                                        "تقارير: 'أسبوعيًا'"
                                ]
                        },
                        {
                                id: 3,
                                img: pakge3,
                                list: [
                                        "إدارة: '3 منصات إعلانية'",
                                        "عدد المنشورات: '25 شهريًا'",
                                        "تصميم المحتوى: '25 شهريًا'",
                                        "كتابة المحتوى الإبداعي: '25 محتوى'",
                                        "دراسة المنافسين: 'يوجد'",
                                        "الرد على التعليقات والرسائل",
                                        "إنشاء حملة إعلانية: '$70'",
                                        "مدة الإدارة: 'شهر'",
                                        "تقارير: 'أسبوعيًا'"
                                ]
                        },
                        {
                                id: 4,
                                img: pakge4,
                                list: [
                                        "إدارة: '4 منصات من اختيارك'",
                                        "عدد المنشورات: '30 شهريًا'",
                                        "تصميم المحتوى: '30 شهريًا'",
                                        "كتابة المحتوى الإبداعي: '30 محتوى'",
                                        "دراسة المنافسين: 'يوجد'",
                                        "الرد على التعليقات والرسائل",
                                        "إنشاء حملة إعلانية: '$120'",
                                        "مدة الإدارة: 'شهر'",
                                        "تقارير: 'أسبوعيًا'"
                                ]
                        },
                        ]
                },
        },
        {

                id: 5,
                name: `تصميم المواقع`,
                nameEn: `Website design.`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS2,
                title: "تعتبر واجهة المستخدم من العوامل الأساسية في تطوير المواقع،",
                desc: " حيث تلعب دورًا حاسمًا في تحسين تجربة المستخدم وجعلها سهلة وممتعة.يشمل تنظيم الألوان والأشكال والرموز ،                وحتي نحقق تفاعلية ممتازة بين المستخدم والموقع",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'صميم جذاب وبسيط للاستخدام من قبل العملاء.',
                        item2: ' استخدام ألوان وأشكال ملفتة لإبراز المعلومات الهامة.',
                        item3: 'نعزز التحويلات وعائد  الاستثمار.',
                        item4: 'نزود ولاء العملاء وتدفق عدد المستخدمين.',
                },
                titleEn: "The user interface is an essential factor in website development",
                descEn: `as it plays a crucial role in improving the user experience and making it easy and enjoyable.
 Includes organizing colours, shapes and symbols,
 In order to achieve excellent interaction between the user and the site`,
                listEn: {
                        item1: `Attractive and simple design for customers to use.`,
                        item2: `Use eye-catching colors and shapes to highlight important information.`,
                        item3: `We boost conversions and ROI.`,
                        item4: `We provide customer loyalty and user flow.`,
                },
                article: {
                        title: `(تصميم المواقع ملازم لبداية نجاحك) `,
                        desc: `
أصبح يتردد على مسامعك كثيراً مصطلح تصميم المواقع الإلكترونية وبدأت تشعر بأنك متأخر عن مواكبة العصر والتطور التكنولوجي المستمر لذلك عملك أصبح بحاجة شديدة لوجود المواقع.
`,
                        because: `- مافائدة لجوئك لتصميم موقع خاص بك ؟`,
                        list: [
                                {
                                        title: `وجود تصميم فريد ومميز لموقعك يلعب دورا حاسماً فى تحسين تجربة المستخدم وجعلها سهلة و ممتعة،ويعد الواجهة الأساسية التي تشمل تنظيم الألوان ،والأشكال ، و الرموز .`,
                                        desc: ``
                                },
                        ],
                        because2: `      • أهمية تصميم موقع إلكتروني للأنشطة التجارية ؟`,
                        list2: [
                                {
                                        title: `١- تلبية احتياجات العملاء.`,
                                        desc: `يجب أن ترتكز مزايا الموقع ونمط التصميم على طبيعة العملاء و متطلباتهم ، فهو بالنهاية موجه إليهم وليكون إستخدامه أمرا محبذا و سهلا .`
                                },
                                {
                                        title: `٢- تكوين انطباع إيجابى عن علامتك التجارية.`,
                                        desc: `سهولة الاستخدام و الواجهات الجذابة التى يوفرها موقعك تزيد من رضا العميل وتشعره بالراحة خلال تصفح الموقع ، يعطى ذلك انطباعاً إيجابيا باحترافيتك و مصداقيتك بالنسبة إليه ، مما يحسن من صورة علامتك التجارية ويجعل المستخدم عميلا محتملاً لخدماتك .`
                                },
                                {
                                        title: `٣- تقليل التكلفة.`,
                                        desc: `يضع مصمم المواقع كفاءة النتيجة النهائية نصب عينيه منذ البداية ، من حيث الجودة و الأداء و فعالية و ضرورة الخدمات المضمنة`
                                }
                        ],
                        because3: `  • ماهى سمات الموقع الإلكتروني الاحترافى ؟`,
                        list3: [
                                {
                                        title: `١- البساطة و الوضوح.`,
                                        desc: `تؤثر بساطة التصميم فى مظهر الموقع ومدى وضوحه ، فهاتان الصفتان مرتبطتان ببعضهما البعض ، كلما زادت العناصر المضمنة فى واجهة المستخدم ، ازداد تعقيد الصفحة.`
                                },
                                {
                                        title: `٢- سهولة التنقل و الإجراءات.`,
                                        desc: `كيفية التنقل بين الصفحات وآلية إتمام العمليات تحدد سهولة الإستخدام عند تصميم موقع إلكتروني وتجعل تجربة المستخدم سلسلة`
                                },
                                {
                                        title: `٣- التكييف مع أحجام الشاشات المختلفة`,
                                        desc: `والتصميم الاحترافي يراعى هذا الجانب لأهميته.`
                                },
                                {
                                        title: `٤- التسلسل الهرمي فى تخطيط الصفحات .`,
                                        desc: `عندما تفتح صفحة ويب لموقع إلكتروني يجب أن تقع عنيك مباشرة على أهم عنصر بالصفحة ، أى الهدف الرئيسي منها ، ومن ثم تنجذب عينك للعناصر الأخرى المضمنة.`
                                },
                                {
                                        title: `٥- الجاذبية و التناسق ويعد جزءا هام يجب الاعتناء به. `,
                                        desc: `حيث إن المظهر العام جزءًا أساسيا يجب الاعتناء به عند تصميم موقع إلكتروني.
   كل ذلك يمنح المستخدم تجربة ممتعة و مظهر جذاب .
`
                                },
                        ],
                        because4: `- وحتى تحقق تفاعلية ممتازة بين المستخدم و الموقع ركز علي الأتي :- `,
                        list4: [
                                {
                                        title: `١- تصميم جذاب وبسيط الإستخدام  من قبل العملاء.`,
                                        desc: ``
                                },
                                {
                                        title: `٢- إستخدام ألوان وأشكال ملفتة لإبراز المعلومات الهامة.`,
                                        desc: ``
                                },
                                {
                                        title: `٣-تعزيز التحويلات وعائد الإستثمار.`,
                                        desc: ``
                                },
                                {
                                        title: `٤-تزويد ولاء العملاء و تدفق عدد المستخدمين.`,
                                        desc: ``
                                },
                        ],
                        because5: `     • ماهى أنواع المواقع الإلكترونية التى يجب عليك معرفتها؟`,
                        list5: [
                                {
                                        title: `١- مواقع الويب البسيطة تشمل هذه الفئة مواقع الويب الأساسية والبسيطة التى تتكون من صفحات HTML الثابتة.`,
                                        desc: ``
                                },
                                {
                                        title: `٢- مواقع الويب الديناميكية وهى تستخدم لغات البرمجه النصية و تتيح للمستخدمين التفاعل مع المحتوى `,
                                        desc: ``
                                },
                                {
                                        title: `٣- مواقع الويب المتقدمة وهى تتطلب مهارات عالية جدا فى التصميم.`,
                                        desc: `   تساهم عملية تصميم موقع إلكتروني فى تقليل الأخطاء المرتكبة خلال مراحل التطوير ، إذ تتيح مرحلة التصميم اختبار مظهر الموقع وشكله ومن ثم اعتماده من قبل أصحاب البيزنس.`
                                },
                                {
                                        title: `   • تناسق تصميم الموقع مع هوية العلامة التجارية.`,
                                        desc: `لكل منشأه هوية تجارية تعبر عن نشاطها ، يجب أن يكون تصميم موقع الويب يتناسق معها بصورة جيدة ، وأن يكون معبراً عن العلامة التجارية ويتناسق تماماً معها ، وعناصره مستوحاة من الهوية التجارية للعمل فكلما اقترب تصميم الموقع من هوية العلامة زاد ولاء العملاء وفرص النجاح و التميز من بين جموع المنافسين عبر الإنترنت ، كما يجب مراعاة الخطوط ، و نظام الألوان ،و رسالة العلامة التجارية.`
                                },
                        ],
                        because6: `   • مميزات تصميم المواقع الإلكترونية ؟`,
                        list6: [
                                {
                                        title: `١- سهولة الوصول إلى المحتوى بسرعة وسهولة .`,
                                        desc: ``
                                },
                                {
                                        title: `٢- الاحتفاظ بالتنسيق.`,
                                        desc: ``
                                },
                                {
                                        title: `٣- مشاركة مريحة .`,
                                        desc: ``
                                },
                                {
                                        title: `٤- محتوى متعدد الوسائط.`,
                                        desc: ``
                                },
                                {
                                        title: `٥- تتبع الأداء .`,
                                        desc: ``
                                },
                                {
                                        title: `٦- سهل التحديث `,
                                        desc: ``
                                },
                                {
                                        title: `٧- متوافق مع مختلف الأجهزة و المتصفحات`,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `ختاما ، كل ذلك يتم من خلال معايير و خطوات نحددها لجعل موقعك مميز عن بقية المواقع وسهل الإستخدام.
و لا تنسي أن الهدف الأساسي من تصميم الموقع هو جعل المستخدم يتخذ إجراء معين ولذلك توصلنا أن وجوده مهم للغاية، 
 قم الأن بعمل تصميم لموقعك.`
                                },
                        ],
                }
        },
        {
                id: 6,
                name: `كتابة المحتوى`,
                nameEn: `Content creation.`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS5,
                title: " المحتوى ليس مجرد فقرات تكتب ويتم نشرها.",
                desc: "ولكن عملية إعداد وتخطيط وتحرير بما يتناسب مع تحقيق أهدافك التسويقية",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'كتابة محتوى احترافي بطريقة منظمة.',
                        item2: 'تلبية احتياجات جمهورك المستهدف.',
                        item3: ' ضمان ظهورك المستمر في محركات البحث.',
                        item4: 'تقديم كافة المعلومات الخاصة بك.',
                        item5: 'كتابة محتوى إبداعي بلغات مختلفة.',
                },
                titleEn: "Content is not just paragraphs written and published.",
                descEn: ` But it is a process of preparation, planning and editing that is appropriate for achieving your marketing goals.`,
                listEn: {
                        item1: `Write professional content in an organized manner.`,
                        item2: ` Meet the needs of your target audience.`,
                        item3: `Ensuring your continued visibility in search engines.`,
                        item4: `Provide all your information.`,
                        item5: `Writing creative content in different languages.`,
                },
                article: {
                        title: `أهمية كتابة المحتوى (قوة الكلمات في عصر المعلومات).`,
                        desc: `يشكل كتابة المحتوى أحد أهم عناصر نجاح التواصل في عالم يعتمد بشكل كبير على الإعلام الرقمي والمحتوى الإلكتروني.
 إن قوة الكلمات تتجلى في قدرتها على نقل المعلومات وإيصال الرسائل بشكل فعّال.
لم يعد المحتوى مقتصر على نشر المعلومات فقط بل تطورت فكرة المحتوى والذي أصبح يحتوي على الصور، مقاطع الفيديو، والبودكاست، ووسائل حديثة التي يمكن من خلالها الوصول لأكبر عدد من العملاء.`,
                        because: `- كيفية البدء في كتابة المحتوي :-`,
                        list: [
                                {
                                        title: ` نظرا للمنافسة الشديدة ضروري تتبع مجموعة من الخطوات حتي تنتج محتوي احترافي .`,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `١- البحث قبل البدء في الكتابة.`
                                },
                                {
                                        title: ``,
                                        desc: `٢- استخدم أسلوب مميز.`
                                },
                                {
                                        title: ``,
                                        desc: `٣-عدم الخروج عن نطاق فكرة المحتوى`
                                },
                                {
                                        title: ``,
                                        desc: `٤-الإبداع والابتكار في كتابة المحتوى.`
                                },
                                {
                                        title: ``,
                                        desc: `٥- اختيار عناوين جذابة.`
                                },
                                {
                                        title: ``,
                                        desc: `٦- اختر اللغة المناسبة`
                                },
                                {
                                        title: ``,
                                        desc: `٧- تجنب وجود أخطاء إملائية`
                                },
                        ],
                        because2: `- ما الفائدة التي تعود عليك من الاهتمام بكتابة المحتوي ؟`,
                        list2: [
                                {
                                        title: `عند كتابة محتوى قيم يحتوي على المعلومات المفيدة وتقديمه بشكل منظم للقارئ يساعده على :-`,
                                        desc: `-فهم المحتوى بشكل واضح.`
                                },
                                {
                                        title: ``,
                                        desc: `-بناء الهوية والسمعة.
`
                                },
                                {
                                        title: ``,
                                        desc: `-تحفيز التفاعل والتأثير. 
`
                                },
                                {
                                        title: ``,
                                        desc: `-تسهيل التفاعل الاجتماعي حيث يمكن للكتابة الإبداعية أن تثير المحادثات وتبني مجتمعًا متفاعلًا حول المواضيع المختلفة.`
                                },
                                {
                                        title: ``,
                                        desc: `تحسين تجربة المستخدم لأن المحتوى الجيد يعزز تجربة المستخدم على المواقع والتطبيقات ويُقدّم قيمة فورية للقارئ، مما يعزز الارتباط والولاء`
                                },
                                {
                                        title: ``,
                                        desc: `-توجيه الجمهور والتسويق لأن يلعب دوراً رئيسياً في إرشاد الجمهور وتوجيهه نحو الخدمات أو المنتجات المناسبة.`
                                },
                        ],
                        because3: `• ما أهم مهارات كتابة المحتوى ؟ `,
                        list3: [
                                {
                                        title: `١- مبادىء صناعة المحتوى التسويقى`,
                                        desc: `من أهم مهارات كتابة المحتوى ،هى فهم الكاتب لمبادئ صناعة المحتوى التسويقى فالمحتوى الذى يستخدمة الكاتب ليس محتوى إبداعى أو أدبى بل موجه لتحقيق أهداف معينة.`
                                },
                                {
                                        title: ``,
                                        desc: `  • شخصية العميل في المشروع ،والقدرة على صناعة المحتوى المناسب للعملاء .`
                                },
                                {
                                        title: ``,
                                        desc: ` • مبادىء قمع المبيعات ، لإنتاج محتوى ملائم لكل مرحلة .`
                                },
                                {
                                        title: ``,
                                        desc: `• توجيه المحتوى ليحقق الأهداف التسويقية المطلوبة في المشروع.`
                                },
                                {
                                        title: ``,
                                        desc: `• استخدام أنواع المحتوى المختلفة بما يتناسب مع خطة المحتوى .`
                                },
                                {
                                        title: `٢- التحرير و المراجعة.`,
                                        desc: ` لأن ذلك يساعد على تنقيح الأخطاء وتحسين المحتوى .`
                                },
                                {
                                        title: `٣- التفكير الإبداعي.`,
                                        desc: `تعد مهارة التفكير الإبداعي من أهم مهارات كتابة المحتوى وتعد الأفكار جزء مهم أيضا من عملية تقديم المحتوى .`
                                },
                                {
                                        title: `٤- البحث الجيد.`,
                                        desc: `تتوقف جودة المحتوى غالباً على البحث لاختيار المصادر الملائمة .`
                                },
                                {
                                        title: `٥ - التخطيط.`,
                                        desc: `تتطلب الكتابه التخطيط الجيد لتوظيف الموارد التسويقية بطريقة صحيحة و إنشاء خطة محتوى متكاملة تساعد المشروع على تحقيق أهدافه.`
                                },
                                {
                                        title: `٦- التفكير التحليلى.`,
                                        desc: `وذلك للتحكم على مدى فاعلية المحتوى ، وإذا ساهم فعلا فى تحقيق الأهداف الموضوعة .`
                                },
                                {
                                        title: `٧- إستخدام الأدوات الضرورية لصناعة المحتوى `,
                                        desc: `تمثل الأدوات جزءًا أساسيا من عمل صانع المحتوى فى العالم الرقمى لذا يحتاج الكاتب إلى معرفة أو تعلم كيفية إستخدام الأدوات المفيدة لعملة .`
                                },
                                {
                                        title: `- ختاما، يظل المحتوي هو الركيزة الأساسية وجسر التواصل لنقل الأفكار وتبادل المعلومات نظرا للمقولة الشائعة ( المحتوي هو الملك )`,
                                        desc: ``
                                },
                        ],
                }
        },
        {
                id: 7,
                name: `انشاء المتاجر الالكترونيه`,
                nameEn: ` Establishing electronic stores.`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS6,
                title: "أفضل طريقة لتحقيق الربح هي بناء متجر إلكتروني",
                desc: " لأنها تعد فرصة هائلة لجذب عدد كبير من العملاء وتوفير قاعدة بيانات كبيرة والتوسع في عملية الاستهداف.",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'متجر علي منصات سلة وشوبيفاي و WooCommerce.',
                        item2: 'سريع وبسيط وأمن.',
                        item3: 'إدخال البيانات وإدارة المتجر.',
                        item4: 'التسليم في الوقت المحدد.',
                },
                titleEn: "The best way to make a profit is to build an online store ",
                descEn: " because it is a tremendous opportunity to attract a large number of customers, provide a large database, and expand the targeting process.",
                listEn: {
                        item1: `Store on Basket, Shopify, and WooCommerce platforms.`,
                        item2: `Fast, simple and secure.`,
                        item3: ` Data entry and store management.`,
                        item4: `Delivery on time.`,
                },
                article: {
                        title: `( إنشاء متجر إلكتروني خطوات يتبعها زيادة أرباحك)  `,
                        desc: `فى الماضى كانت فكرة إنشاء متجر إلكتروني ليست سهلة بل صعبة المنال وتحتاج إلى وقت وجهد كبير و رأس مال هائل ،بل الآن فى ظل التطور التكنولوجي و الثورة الإلكترونية أصبح الأمر أسهل مما يتخيل البعض.`,
                        because: `.لماذا يعد إنشاء المتجر أمر هام ؟`,
                        list: [
                                {
                                        title: ``,
                                        desc: `لأن المتجر ببساطة عبارة عن منصة إلكترونية يمكن من خلاله بيع منتجات أو خدمات و شراء بدون تدخل صاحب المتجر ، ويعد أفضل طريقة لتحقيق الربح ، وفرصة هائلة لجذب عدد كبير من العملاء ، توفير قاعدة بيانات كبيرة و التوسع فى عملية الإستهداف الخاصة بك. `
                                },
                                {
                                        title: `★ ولأن سوق التجارة الإلكترونية أصبح فى صعود دائم والفرصة متاحة لكل من يريد استغلالها بالشكل الصحيح والأن تستطيع أن تمتلك متجر علي :-`,
                                        desc: ``
                                },
                        ],
                        because2: `     ★ماهي مزايا إنشاء متجر إلكتروني لنشاطك التجارى؟!`,
                        list2: [
                                {
                                        title: `  ١- بناء العلامة التجارية وتنميتها.
`,
                                        desc: `الهدف من إنشاء متجر إلكتروني ، بناء و إدارة العلامة التجارية عبر الإنترنت وتقوية العلاقة بين الجمهور المستهدف.`
                                },
                                {
                                        title: `٢- بناء ولاء العملاء `,
                                        desc: `عند عمل متجر إلكتروني تستطيع إختيار تصميم يبرز منتجاتك ويمكنك من بناء الثقة و المصداقية مع جمهورك المستهدف.`
                                },
                                {
                                        title: `٣- جمع بيانات الجمهور المستهدف.`,
                                        desc: ` فى حالة عرض منتجاتك على مواقع أخرى وشاهدها بعض العملاء ولم يشترينها فلن تستطيع الوصول إليهم مرة أخرى فى حين إذا حدث ذلك أثناء زيارتهم إلى متجرك الإلكتروني فيكون لديك فرصة لمعرفة عناوين البريد الالكتروني الخاص بهم و تشجعهم على الإشتراك.`
                                },
                                {
                                        title: `٤- تتبع العميل ورحلة الشراء`,
                                        desc: `من الصعب معرفة عدد المرات التى يزور فيها العميل المتاجر التقليدية قبل إتمام عملية الشراء ، لكن إذا كنت تمتلك متجراً إلكترونيا ، فإنه يمكنك قياس جميع النتائج و تتبع كل عميل ورصد تحركاته و مسار رحلته من خلال الإحصائيات والبيانات التي يوفرها المتجر الالكتروني.`
                                },
                                {
                                        title: `٥- التسوق على مدار الساعة`,
                                        desc: `يتيح المتجر الإلكتروني للمسوقين القدرة على مشاهدة المنتجات و إجراء عمليات الشراء دون التقيد بوقت محدد.`
                                },
                                {
                                        title: `٦- توسيع نطاق نشاطك التجارى.`,
                                        desc: `يساعدك فتح متجر إلكتروني على توسيع نطاق نشاطك التجارى ليشمل الوصول إلى شرائح العملاء من مختلف أنحاء العالم أو دولة بعينها.`
                                },
                        ],
                        because3: `   • كيفية إنشاء متجر إلكتروني إحترافى ؟ `,
                        list3: [
                                {
                                        title: `    • إنشاء موقع ووردبريس واختيار اسم النطاق.`,
                                        desc: `عليك بإنشاء موقع مجانى الآن وتستطيع ترقية حسابك فيما بعد من خلال الاصدارات المدفوعة ، تحديد إسم النطاق يعد مفتاح النجاح وبداية تأسيس العلامة التجارية و زيادة شعبيتها بين الجمهور المستهدف وبناء الثقه بين العملاء .`
                                },
                                {
                                        title: `• ثانياً: حدد خطة أسعار مناسبة .`,
                                        desc: `يتيح نظام wordpress  أكثر من خطة أسعار تناسب جميع احتياجات رواد الأعمال و تحدد الخطة المناسبة لك ولمشروعك.`
                                },
                                {
                                        title: `   • ثالثا: بناء المتجر الإلكتروني و تأمينه.`,
                                        desc: `فى هذه المرحلة عليك الإهتمام بعدة مهام أبرزها اختيار التصميم الجذاب الذى يجعل متجرك مميزا و تطوير المتجر ، و إختيار القوالب أو السمات فى ثوانى معدودة بسهولة و سلاسة مذهلة .`
                                },
                                {
                                        title: `   قبل أن تبدأ بإنشاء المتجر الإلكتروني يجب أن تزيح عن طريقك كل العقبات ، ومن أهم العقبات التى قد تقف فى وجهك هى المفاهيم الخاطئة الأكثر شيوعاً عن إنشاء المتاجر الإلكترونية ، كتلك الذى تقول إنه ينبغى أن يكون لديك الكثير من المنتجات.`,
                                        desc: ``
                                },
                                {
                                        title: `  ختاما، نحن نعمل جاهدين على إنشاء المتجر الإلكتروني الخاص بك بشكل محترف ومتطور حتى تواكب التطور و تكون دائماً متاح فى ساحة المنافسة بكافة الطرق`,
                                        desc: ``
                                },
                        ],
                        because4: `كيف تعمل محركات البحث ؟ `,
                        list4: [
                                {
                                        title: `تستطيع محركات البحث الوصول إلى مليارات الصفحات بانتظام من خلال ما يسمى ب web crawlers أو زواحف الويب وتبدأ العملية بتتبع هذة الروبوتات للروابط على الإنترنت للعثور على الصفحات الجديدة ، حتى تتمكن من فهرستها و ضمها إلى قاعدة البيانات الخاصة بها ، ثم تأتى مرحلة تحليل المعلومات التى تتضمنها هذة الصفحات ، سواء كانت صور أو نصوص أو مقاطع فيديو ، لتحديد ما إذا كان محتواها فريداً وغير منسوخ من صفحات أخرى أم لا ، تنتهى بترتيبها حسب الأفضلية لضمان حصول المستخدم على أفضل نتائج بحث.`,
                                        desc: ``
                                },
                                {
                                        title: `     ★ لماذا يجب الإهتمام بتحسين محركات البحث ؟ `,
                                        desc: `لأنه يساعد علي :-`
                                },
                                {
                                        title: `•جذب الجمهور المستهدف `,
                                        desc: ``
                                },
                                {
                                        title: `• البقاء فى الصدارة.`,
                                        desc: ``
                                },
                                {
                                        title: `• سهولة قياس النتائج.`,
                                        desc: ``
                                },
                                {
                                        title: `   • استثمار مجانى. `,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `و أخيراً ؛ كن على علم أن استراتيجية تحسين محركات البحث ليست مجرد مجموعة من الخطوات التقنية بل جزء من خطة تسويقية شاملة تستهدف تحقيق أهدافك على المدى الطويل.
واكب العصر والتطور وتصدر الظهور دائماً وقم ببداية عملك مع شركات التسويق التى تحقق لك النجاح الباهر .
فإننا نعمل من أجل تقدمك .`
                                },
                        ],
                }
        },
        {
                id: 8,
                name: `تحسين نتائج البحث SEO`,
                nameEn: `Improving search results (SEO).`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS8,
                title: "عبارة عن كلمات محددة يتم استخدامها وفقاً لطبيعة المحتوى",
                desc: "فهذا يعمل على تحسين موقع الويب لديك لتعزيز ظهوره بشكل كبير عندما يتم البحث  عن منتجات أو خدمات لها علاقة بعملك.",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: 'ظهور صفحاتك بأفضل شكل في نتائج البحث',
                        item2: 'زيادة احتمالية جذب الأنظار والعملاء المتوقعين والحقيقين إلى عملك.',
                        item3: 'زيادة حجم مبيعاتك.',
                },
                titleEn: "These are specific words that are used according to the nature of the content. ",
                descEn: `This improves your website to greatly enhance its visibility when searching for products or services related to your business.`,
                listEn: {
                        item1: ` Your pages appear best in search results.`,
                        item2: `Increase the likelihood of attracting attention, potential and real customers to your business.`,
                        item3: `Increase your sales volume.`,
                },
                article: {
                        title: `( واكب التطور وتصدر محركات البحث) `,
                        desc: `يتردد عليك كثيرا تحسين محركات البحث SEO ولم تعلم جيداً ماذا يعنى هذا المصطلح دعنا نتفق على أن تحسين ظهور موقعك لدى محركات البحث يتطلب عملا دؤوبا للحفاظ على مركز جيد.`,
                        because: `  • ماهو ال SEO  ؟ `,
                        list: [
                                {
                                        title: `هو إختصار لجملة Search Engine Optimization أو تحسين محركات البحث ويعبر عن الممارسات التي تهدف إلى زيادة ورفع جودة حركة مرور الويب ( عدد الزيارات إلى موقع إلكتروني معين ) وذلك من خلال نتائج البحث غير المدفوعة على محركات البحث ، وعلى الرغم من أن المصطلح إختصار ل "تحسين محركات البحث" إلا أن هذا المفهوم يتعلق بالأشخاص أكثر من تعلقة بمحركات البحث ، فهو يدور حول:`,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `  • فهم ما يبحث عنه المستخدمون على شبكة الإنترنت.`
                                },
                                {
                                        title: ``,
                                        desc: `  • الإجابات التى يسعون للعثور عليها .`
                                },
                                {
                                        title: ``,
                                        desc: `  • الكلمات التى يستخدمونها فى بحثهم .`
                                },
                                {
                                        title: ``,
                                        desc: `   • نوع المحتوى الذى يستهلكونه .`
                                },
                                {
                                        title: ``,
                                        desc: ` عندما تتمكن من تحديد هذة الأمور سيصبح فى وسعك ربط المستخدمين الذين يقومون بعمليات البحث على الإنترنت مع الحلول المناسبة التى يقدمها موقع الويب الخاص بك.`
                                },
                        ],
                        because2: `       • ماهى عملية تحسين محركات البحث SEO؟ `,
                        list2: [
                                {
                                        title: ` هي عبارة عن كلمات محددة يتم استخدامها وفقا لطبيعة المحتوى بإستخدام الكلمات المفتاحية.
وهذا يعمل على تحسين موقع الويب لديك لتعزيز ظهوره بشكل كبير عندما يتم البحث عن منتجات أو خدمات لها علاقة بعملك .`,
                                        desc: ``
                                },
                                {
                                        title: `   ★ لهذا مهم وضروري في :- `,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `   • ظهور صفحاتك بأفضل شكل فى نتائج البحث.`
                                },
                                {
                                        title: ``,
                                        desc: `   • زيادة احتمالية جذب الأنظار و العملاء المتوقعين و الحقيقين إلى عملك .`
                                },
                                {
                                        title: ``,
                                        desc: `   • زيادة حجم مبيعاتك . `
                                },
                        ],
                        because3: `  • عليك معرفة أن ال SEO له معايير وهما نوعين:- `,
                        list3: [
                                {
                                        title: `التحسينات داخل الموقع on- page SEO -١`,
                                        desc: `   وهى عبارة عن مجموعة أوامر تقوم بفعلها داخل الموقع تشمل`
                                },
                                {
                                        title: ``,
                                        desc: `* كتابة محتوى عالى الجودة والتأكد من أن المحتوى مفيد و مميز وجذاب للمستخدم و يعمل على لفت الإنتباه .`
                                },
                                {
                                        title: ``,
                                        desc: `* إستخدام الكلمات المفتاحية بشكل صحيح ولابد أن تكون الكلمات مذكورة بالمقالة أو العنوان الفرعى أو الرئيسى الخاص بالمحتوى ذاته .`
                                },
                                {
                                        title: ``,
                                        desc: `* تحسين البنية التحتية للموقع بشكل مستمر و التأكد من إن الموقع سريع الإستجابة.`
                                },
                                {
                                        title: `٢- التحسينات خارج الموقع off-pageSEO.`,
                                        desc: `هى عبارة عن أشياء وإجراءات نقوم بفعلها خارج موقعك لكى تفرق معك كثيراً.`
                                },
                                {
                                        title: ``,
                                        desc: `• الشبكات الاجتماعيه كلما كان لديك هوية على الشبكات و تفاعل مستمر على المحتوى الذى تقوم بتقديمة كلما ما كان موقعك متميز .`
                                },
                                {
                                        title: ``,
                                        desc: `• نشر موقعك فى محركات البحث توفير للوقت وتقوم أنت بتعريفهم بموقعك `
                                },
                                {
                                        title: ``,
                                        desc: `• المنتديات والمدونات: أن تقوم بنشر رابط موقعك بشكل دائم  لكى تكتسب عملاء جدد.`
                                },
                                {
                                        title: `• استهداف دولة: `,
                                        desc: `فى حين أنك ترغب توصيل نشاطك أو محتوى موقعك لدولة معينة وهذا يفرق معك كثيرا و يظهر موقعك فى أول نتائج البحث للدولة. `
                                },
                                {
                                        title: ` ومع العلم إن محركات البحث ليست Google فقط لكنها تويتر ، و فيس بوك ، سناب شات وغيرهم `,
                                        desc: ``
                                },
                        ],
                        because4: `كيف تعمل محركات البحث ؟ `,
                        list4: [
                                {
                                        title: `تستطيع محركات البحث الوصول إلى مليارات الصفحات بانتظام من خلال ما يسمى ب web crawlers أو زواحف الويب وتبدأ العملية بتتبع هذة الروبوتات للروابط على الإنترنت للعثور على الصفحات الجديدة ، حتى تتمكن من فهرستها و ضمها إلى قاعدة البيانات الخاصة بها ، ثم تأتى مرحلة تحليل المعلومات التى تتضمنها هذة الصفحات ، سواء كانت صور أو نصوص أو مقاطع فيديو ، لتحديد ما إذا كان محتواها فريداً وغير منسوخ من صفحات أخرى أم لا ، تنتهى بترتيبها حسب الأفضلية لضمان حصول المستخدم على أفضل نتائج بحث.`,
                                        desc: ``
                                },
                                {
                                        title: `     ★ لماذا يجب الإهتمام بتحسين محركات البحث ؟ `,
                                        desc: `لأنه يساعد علي :-`
                                },
                                {
                                        title: `•جذب الجمهور المستهدف `,
                                        desc: ``
                                },
                                {
                                        title: `• البقاء فى الصدارة.`,
                                        desc: ``
                                },
                                {
                                        title: `• سهولة قياس النتائج.`,
                                        desc: ``
                                },
                                {
                                        title: `   • استثمار مجانى. `,
                                        desc: ``
                                },
                                {
                                        title: ``,
                                        desc: `و أخيراً ؛ كن على علم أن استراتيجية تحسين محركات البحث ليست مجرد مجموعة من الخطوات التقنية بل جزء من خطة تسويقية شاملة تستهدف تحقيق أهدافك على المدى الطويل.
واكب العصر والتطور وتصدر الظهور دائماً وقم ببداية عملك مع شركات التسويق التى تحقق لك النجاح الباهر .
فإننا نعمل من أجل تقدمك .`
                                },
                        ],
                }
        },
        {
                id: 9,
                name: `تأسيس شركه بريطانيه`,
                nameEn: `تأسيس شركه بريطانيه`,
                price: 'يختلف سعر الخدمة حسب الطلب',
                img: servS9,
                title: "",
                desc: "",
                servTo: 'نوفر لك :',
                servToEn: ' We provide you with:',
                list: {
                        item1: '',
                        item2: '',
                        item3: '',
                        item4: '',
                },
                titleEn: "",
                descEn: "",
                listEn: {
                        item1: '',
                        item2: '',
                        item3: '',
                        item4: '',
                }
        }
]

export const works = [
        {
                id: 4,
                name: `برمجة المواقع`,
                desc: `نقدم لك مواقع تفاعلية ومبتكرة ودعم فني مستمر.`,
                img: servS4,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
        {
                id: 3,
                name: `التصميم`,
                desc: `تصميمات خاصة السوشيال ميديا تنقل صورة قوية عنك وتكون انطباع إيجابي لدي عملائك.`,
                img: servS1,
                listImages: [
                        de1,
                        de2,
                        de3,
                        de4,
                        de5,
                        de6,
                        de7,
                        de8,
                ]
        },
        {
                id: 1,
                name: `إدارة المنصات`,
                desc: ` نحقق لك أفضل عائد وهوية احترافية لمشروعك من خلال إدارة المنصات.`,
                img: servS3,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
        {
                id: 2,
                name: `تصميم المواقع`,
                desc: ` نوفر لك تحسين تجربة المستخدم ونعزز عدد التحويلات لموقعك.`,
                img: servS2,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
        {
                id: 5,
                name: `تصميم موبايل ابليكشن`,
                desc: `نسهل علي عملائك عمليات البيع والشراء من خلال الموبايل أبلكيشن.`,
                img: servS7,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
        {

                id: 6,
                name: `كتابة المحتوى`,
                desc: ` نقدم لك محتوي احترافي منظم جذاب وقيم.`,
                img: servS5,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
        {
                id: 7,
                name: `انشاء المتاجر الالكترونيه`,
                desc: `حقق ربح ووسع عملية استهدافك من خلال امتلاكك لمتجر إلكتروني خاص بك.`,
                img: servS6,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
        {
                id: 8,
                name: `تحسين نتائج البحث SEO`,
                desc: `ظهور صفحاتك علي نتائج محركات البحث وتزويد حجم مبيعاتك مسئولياتنا.`,
                img: servS8,
                listImages: [
                        p1,
                        p2,
                        p3,
                        p4,
                ]
        },
]

export const ListPackages = [
        {
                id: 1,
                Text: ` البحث عن منتج ناجح بدراسة أكاديمية ونضيفه لمتجرك
 ونضبط امورك مع الموردين`
        },
        {
                id: 2,
                Text: `عمل دراسة قطاع ودراسة سوق مستهدف بالاضافة لتحليل التنافسية 
لمنتجك لضمان نجاح توجهك`
        },
        {
                id: 3,
                Text: ` عمل ورقة مفهوم (Concept Statement) وترويجها كاستطلاع لعملائك
 المحتملين لضمان تطوير المنتج أفضل من المنافسين`
        },
        {
                id: 4,
                Text: `عمل استطلاع رأي نية الشراء (BIS) بعد اختيار المنتج للفئة المستهدفة 
وجمع بيانات عملاء محتملين لمنتجك قبل اطلاق المتجر`
        },
        {
                id: 5,
                Text: ` كتابة الاستراتيجية التسويقية لمنتجك وتوجهه لفئتك
 المستهدفة بالشكل الصحيح`
        },
        {
                id: 6,
                Text: ` نرسم لك خطة تجارية مستدامة بحيث تنتقل من عالم الدروبشيبينق
 لعالم امتلاك المخزون وانشاء براندك الخاص ومضاعفة الأرباح`
        },
        {
                id: 7,
                Text: ` انشاء متجر الكتروني من الألف الى الياء مع ضبط الاعدادات وكتابة
 السياسات الخاصة بالاسترجاع والاستبدال وشروط الخدمة وسياسة الخصوصية`
        },
        {
                id: 8,
                Text: ` نربط لك طرق الدفع 
(ابل باي، مدى، ماستركارد، امريكان اكسبرس، اس تي سي باي)`
        },
        {
                id: 9,
                Text: ` انشاء هوية بصرية احترافية من شعار وبنرات وتصاميم 
المنتجات داخل المتجر`
        },
        {
                id: 10,
                Text: `نكتب لك محتوى توصيف المنتجات بالكامل بالاضافة الى ثلاث مدونات
 خاصة بمجالك مع ضبط اساسيات تحسين الظهور على محرك البحث`
        },
        {
                id: 11,
                Text: `كتابة الرؤية والمهمة والأهداف بطريقة احترافية
 تدعم استدامة متجرك`
        },
        {
                id: 12,
                Text: ` صياغة وتنفيذ حملة اعلانية على احد منصات مواقع التواصل 
الاجتماعي ويتم تحديدها بناءً على المنتج والفئة المستهدفة`
        },
        {
                id: 13,
                Text: ` تصميم ثلاث بنرات احترافية لمواقع التواصل
 الاجتماعي الخاصة بمتجرك`
        },
        {
                id: 14,
                Text: ` كتابة استراتيجية لإدارة مواقع التواصل الاجتماعي الخاصة
 بمتجرك لزيادة التفاعل والمتابعين`
        },
        {
                id: 15,
                Text: `كتاب أسرار التجارة المليونية معد من قبل فريق تيكاتوك مجانا`
        },
]

export const Basic = [
        {
                id: 1,
                Text: `شركتك الخاصة في المملكة المتحدة`
        },
        {
                id: 2,
                Text: `عنوان المكتب المسجل (سنة واحدة مجانًا، ثم 59 دولارًا سنويًا)`
        },
        {
                id: 3,
                Text: `استشارة بشأن حساب Business Stripe في المملكة المتحدة`
        },
        {
                id: 4,
                Text: `شهادة تأسيس`
        },
        {
                id: 5,
                Text: `يتم تسليم مستندات الشركة إلى بريدك الإلكتروني فقط`
        },
        {
                id: 6,
                Text: `دعم البريد الإلكتروني فقط`
        }
]

export const Premium = [
        {
                id: 1,
                Text: `خدمة محسنة وسريعة وحصرية`
        },
        {
                id: 2,
                Text: `أولوية الطلب`
        },
        {
                id: 3,
                Text: `الدردشة والدعم عبر الهاتف`
        },
        {
                id: 4,
                Text: `بطاقة      SIM      حقيقية  في المملكة المتحدة برصيد 5 جنيهات 
إسترلينية`
        },
        {
                id: 5,
                Text: `مدير حساب مخصص`
        },
        {
                id: 6,
                Text: `موقع ويب احترافي لأعمال الشركة ومقبول بنسبة 100 %`
        },
        {
                id: 7,
                Text: ` استشارة بشأن حساب  Wise Business (لا تقبل        Wise     حاليًا  الشركات البريطانية) نطاق .com مجاني`
        },
        {
                id: 8,
                Text: `3 شعارات مخصصة صممها فريقنا من مصممي الجرافيك المهرة
المكافآت`
        },
]

export const logos = [
        {
                img: ll1,
        },
        {
                img: ll2,
        },
        {
                img: ll3,
        },
        {
                img: ll4,
        },
        {
                img: ll5,
        },
        {
                img: ll6,
        },
        {
                img: ll7,
        },
        {
                img: ll8,
        },
        {
                img: l4,
        },
        {
                img: l5,
        },
        {
                img: l6,
        },
        {
                img: l8,
        },
        {
                img: l10,
        },
        {
                img: l13,
        },
        {
                img: l14,
        },
        {
                img: l15,
        },
        {
                img: l17,
        },
        {
                img: l20,
        },
        {
                img: l23,
        },
]
export const partner = [
        {
                img: part1,
        },
        {
                img: part2,
        },
        {
                img: part3,
        },
        {
                img: part4,
        },
        {
                img: part5,
        },
]

export const related = [
        {
                id: 1,
                text: '100%',
                type: 'تعليق'
        },
        {
                id: 2,
                text: '666',
                type: 'نشاط'
        },
        {
                id: 3,
                text: '100',
                type: 'العروض التوضيحية'
        },
        {
                id: 4,
                text: '234',
                type: 'إجمالي الصفحات'
        },
]

export const blogs = [
        {
                id: 1,
                image: blog,
                name: 'أنواع واستراتيجيات التسويق ',
                titleList: 'أنواع واستراتيجيات التسويق متنوعة وتعتمد على أهداف الشركة والجمهور المستهدف. إليك بعض الأنواع الرئيسية واستراتيجياتها:',
                List: [
                        {
                                name: 'التسويق B2B: يركز على الشركات التي تبيع منتجاتها أو خدماتها لشركات أخرى',
                        },
                        {
                                name: 'التسويق B2C: يستهدف الشركات التي تبيع مباشرةً للمستهلك',
                        },
                        {
                                name: 'التسويق الرقمي: يشمل جميع جهود التسويق التي تستخدم الإنترنت أو الأجهزة الإلكترونية',
                        },
                        {
                                name: 'تسويق المحتوى: يتمحور حول إنشاء ونشر محتوى قيم وملائم لجذب جمهور مستهدف',
                        },
                        {
                                name: 'التسويق عبر وسائل التواصل الاجتماعي: يستخدم منصات الوسائط الاجتماعية لتحقيق أهداف تسويقية متنوعة',
                        },
                        {
                                name: 'التسويق عبر البريد الإلكتروني: يشمل إرسال رسائل بريد إلكتروني لتعزيز المنتجات أو الخدمات'
                        },
                        {
                                name: 'تحسين محركات البحث (SEO): يركز على تحسين موقع الويب لزيادة ترتيبه في نتائج البحث'
                        },
                        {
                                name: 'التسويق المؤثر: يعتمد على استخدام الأشخاص ذوي النفوذ لترويج المنتجات'
                        },
                ],
                desc: `كل استراتيجية لها مزاياها وتقنياتها الخاصة، ويمكن دمج أكثر من استراتيجية لتحقيق أفضل النتائج. من المهم اختيار الاستراتيجية المناسبة بناءً على أهداف العمل والموارد المتاحة والجمهور المستهدف`
        },
        {
                id: 3,
                image: blog,
                name: 'أفضل استراتيجية لزيادة الوعي',
                titleList: 'أفضل استراتيجية لزيادة الوعي بالعلامة التجارية تعتمد على فهم جمهورك والتواصل معهم بطريقة تجعل علامتك التجارية لا تُنسى. إليك بعض الاستراتيجيات الفعالة:',
                List: [
                        {
                                name: 'بناء ملامح تجارية فريدة: اجعل علامتك التجارية مميزة بخصائص تجعلها لا تُنسى',
                        },
                        {
                                name: 'تبني وجه إعلامي مناسب: استخدم شخصيات تمثل علامتك التجارية بشكل إيجابي',
                        },
                        {
                                name: 'سرد قصة: اربط منتجاتك بقصص تلمس المشاعر وتبقى في الذاكر',
                        },
                        {
                                name: 'بناء مجتمع على مواقع التواصل الاجتماعي: استخدم منصات التواصل لبناء مجتمع حول علامتك التجارية',
                        },
                        {
                                name: 'الإعلان في أكثر من مكان: ضع إعلاناتك في مواقع متعددة لزيادة التعرض',
                        },
                        {
                                name: 'تحسين المحتوى لمحركات البحث (SEO): استخدم الكلمات الرئيسية والمحتوى الجذاب لتحسين ترتيبك في نتائج البحث',
                        },
                        {
                                name: 'التدوين الاستضافي: اكتب مقالات ضيف لمواقع أخرى للوصول إلى جمهور جديد',
                        },
                ],
                desc: `هذه الاستراتيجيات تساعد في بناء الوعي بالعلامة التجارية وتعزيز الصورة الإيجابية لها في أذهان الجمهور. من المهم الجمع بين هذه الاستراتيجيات وتكييفها مع احتياجات وتفضيلات جمهورك المستهدف.`
        },
        {
                id: 3,
                image: blog,
                name: 'تحسين معدل التحويل',
                titleList: 'تحسين معدل التحويل على الموقع يعني زيادة نسبة الزوار الذين يقومون بإجراء معين مثل الشراء أو التسجيل. إليك بعض الاستراتيجيات لتحسين معدل التحويل:',
                List: [
                        {
                                name: 'تحسين تجربة المستخدم: تأكد من أن تصميم موقعك سهل الاستخدام ويوفر تجربة مستخدم ممتازة',
                        },
                        {
                                name: 'المحتوى الجذاب والمفيد: قدم محتوى ذو قيمة عالية يجذب الزوار ويحفزهم على القيام بالإجراء المطلوب',
                        },
                        {
                                name: 'لعروض والحسومات: استخدم العروض الترويجية والخصومات لتشجيع الزوار على اتخاذ قرار الشراء',
                        },
                        {
                                name: 'تحسين سرعة الموقع: سرعة تحميل الصفحات مهمة جدًا، حيث أن الزوار قد يغادرون إذا كان الموقع بطيء',
                        },
                        {
                                name: 'التوافق مع الأجهزة المحمولة: تأكد من أن موقعك يعمل بشكل جيد على الأجهزة المحمولة',
                        },
                ],
                desc: 'هذه بعض الطرق الأساسية، ولكن يجب الأخذ بعين الاعتبار أن تحسين معدل التحويل هو عملية مستمرة تتطلب التجربة والتحليل والتحسين المستمر'
        },
        {
                id: 4,
                image: blog,
                name: 'أهمية التجارة الإلكترونية ',
                titleList: 'تظهر أهمية التجارة الإلكترونية في العصر الحالي ضمن نقاط رئيسية:',
                List: [
                        {
                                name: 'توسيع العلامة التجاريّة: التجارة الإلكترونية تُمكّن نقل التجارة التقليدية إلى علامة تجارية مبتكرة ومعروفة لدى الكثيرين. يمكن تقديم الخدمات والمنتجات على مدار الساعة والتفاعل مع العملاء عبر وسائل التواصل الاجتماعي.',
                        },
                        {
                                name: 'ملاءمة لظروف العملاء: التجارة الإلكترونية توفر مرونة للعملاء، حيث يمكنهم زيارة المتجر في أي وقت مناسب لهم دون قيود زمنية أو مكانية.',
                        },
                        {
                                name: 'توسيع الفئات المستهدفة: يمكن الوصول إلى ملايين الأشخاص حول العالم من خلال التجارة الإلكترونية، مما يزيد من نطاق العمل والجمهور.',
                        },
                        {
                                name: 'جمع البيانات لتحسين الخدمات: من خلال أنظمة التجارة الإلكترونية، يمكن جمع بيانات الزوار وفهم حاجاتهم وتفاعلهم، مما يساعد في تحسين المنتجات والخدمات.',
                        },
                        {
                                name: 'تقليل التكاليف مقابل زيادة الأرباح: يمكن البدء في التجارة الإلكترونية بتكاليف منخفضة وتشغيل أقل، مما يقلل المخاطر ويزيد من الأرباح.',
                        },
                ],
                desc: 'في النهاية، التجارة الإلكترونية تمثل تحولًا هامًا في طريقة التجارة وتقديم الخدمات، وهي مهمة للشركات والأفراد على حد سواء.'
        },
]

export const Offers = [
        {
                id: 1,
                image: offer,
                name: 'كاتب محتوى',
        },
        {
                id: 2,
                image: offer,
                name: 'مطور ويب',
        },
        {
                id: 3,
                image: offer,
                name: 'خبير seo',
        },
        {
                id: 4,
                image: offer,
                name: 'موشن جرافيك',
        },
]

export const faqs = [
        {
                id: 1,
                title: `ما هي الخطوة الأولى لإنشاء متجر إلكتروني؟ `,
                desc: ` الخطوة الأولى هي تحديد السوق المستهدف والمنتجات التي ترغب في بيعها، ثم اختيار المنصة المناسبة لإنشاء المتجر.`,
        },
        {
                id: 2,
                title: `هل يمكنك إنشاء متجر إلكتروني بدون خبرة برمجية؟ `,
                desc: `نعم، هناك منصات مثل Shopify & WooCommerce توفر أدوات سهلة الاستخدام لإنشاء متجر دون الحاجة لخبرة برمجية.`,
        },
        {
                id: 3,
                title: `ما هي التكاليف المتوقعة لإنشاء متجر إلكتروني؟ `,
                desc: `التكاليف تختلف بناءً على عدة عوامل مثل تصميم الموقع، الاستضافة، والخدمات الإضافية مثل الدعم الفني والتسويق.`,
        },
        {
                id: 4,
                title: `كيف تحمي متجرك الإلكتروني من الهجمات الإلكترونية؟ `,
                desc: ` يجب استخدام شهادات SSL لتأمين الاتصالات، واختيار منصة تجارة إلكترونية توفر تحديثات أمنية منتظمة.`,
        },
        {
                id: 5,
                title: `ما هي أفضل طرق التسويق لمتجرك الإلكتروني؟`,
                desc: `التسويق عبر محركات البحث (SEO) والإعلانات المدفوعة، بالإضافة إلى استخدام وسائل التواصل الاجتماعي هي من أفضل الطرق.`,
        },
        {
                id: 6,
                title: `كيف يمكنك قياس نجاح متجرك الإلكتروني؟`,
                desc: `يمكن قياس النجاح من خلال معدلات التحويل، وحجم المبيعات، والتفاعل مع العملاء عبر المتجر الإلكتروني.`,
        }
];
