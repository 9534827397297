import React from 'react'
import Header from '../components/Landing/Header'
import StartNow from '../components/Landing/StartNow/StartNow'
import Services from '../components/Landing/Services/Services'
import Work from '../components/Landing/Work/Work'
import Review from '../components/Landing/Review/Review'
import Form from '../components/Landing/Form/Form'
import Footer from '../components/Landing/footer/Footer'

const Landing = () => {
  return (
    <>
      <Header />
      <StartNow />
      <Services />
      <Work />
      <Review />
      <Form />
      <Footer />
    </>
  )
}

export default Landing