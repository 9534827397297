import { Box, CardMedia, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img from './../../../assets/WhatsApp-Image-2024-04-28-at-12.png'
const Work = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 470, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ my: 2 }}>
                    <Box sx={{ mb:2 }} >
                        <Typography variant={'h5'} color={'#4C164C'} mb={1} >الأعمال السابقة</Typography>
                        <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '50%', mb: 1 }} />
                        <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '25%' }} />
                    </Box>
                    <Carousel
                        autoPlay={true}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        infinite={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="transform 300ms ease-in-out"
                        transitionDuration={1000}
                        containerclassName="carousel-container"
                        dotListclassName="custom-dot-list-style"
                        itemclassName="carousel-item-padding-40-px"
                        rewindWithAnimation={true}
                        removeArrowOnDeviceType={['desktop', 'mobile', 'table']}
                    >
                        <CardMedia alt='test' component={'img'} src={img} sx={{ width: '95%' }} />
                        <CardMedia alt='test' component={'img'} src={img} sx={{ width: '95%' }} />
                        <CardMedia alt='test' component={'img'} src={img} sx={{ width: '95%' }} />
                        <CardMedia alt='test' component={'img'} src={img} sx={{ width: '95%' }} />
                    </Carousel>
                </Box>
            </Container>
        </>
    )
}

export default Work