import { Box, Typography } from '@mui/material'
import React from 'react'
import './style.css'
const Card = ({ num, bg, color, description }) => {
    return (
        <>
            <Box sx={{ border: '1px solid #10446F', p: 1, position: 'relative', borderRadius: '8px', my:2 }}>
                <Box className={num === '2' ? 'numberLeft' : 'number'} >
                    {num}
                </Box>
                <Box sx={{ background:bg, borderRadius:'8px', color:color, p:2, }} >
                    <Typography >
                        {description}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default Card
