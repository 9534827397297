import React from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import ContainerBox from '../components/glopal/ContainerBox'
import img from './../assets/hero/Group 1673.svg'
import { Box, CardMedia, Typography, useMediaQuery, Container } from '@mui/material'
import Transfers from '../components/glopal/Transfers'
import WhatWeWillOfferYou from '../components/AboutUs/WhatWeWillOfferYou'
import ServiceMarkit from '../components/AboutUs/ServiceMarkit'
import Marquee from 'react-marquee-slider'
import { useTheme } from '@emotion/react'
import { logos, partner } from '../constants'
const AboutUs = () => {
    const { t } = useTranslation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Header path={t("linksPages.aboutUs")} />
            <ContainerBox img={img} >
                <Box my={2} >
                    <Typography variant={'h5'} color={'secondary.main'} sx={{ fontWeight:'600' }}  >{t("aboutUs.platform")}</Typography>
                    <Typography my={1} >{t("aboutUs.title")}</Typography>
                    <Typography>{t("aboutUs.description")}</Typography>
                </Box>
            </ContainerBox>
            <WhatWeWillOfferYou />
            <ServiceMarkit />
            <Container maxWidth={'lg'} >
                <Box my={4} >
                    <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                        <Typography mb={2} variant='h6' color={'#4C164C'} > <span style={{ color: '#FABB25' }} >{'كبار  '}</span>
                            {'شركاء النجاح'}</Typography>
                    </Box>
                    <Marquee velocity={isMobile ? 10 : 40} 
                        radius={50} direction='rtl' > 
                        {partner.map((image, index) => (
                            <Box sx={{
                                border: '1px solid #CCE1E5', background: '#e5e3e3', borderRadius: '8px', mx: 2,
                                my: 1,
                                ':hover': {
                                    background: '#CCE1E5',
                                    boxShadow: '0px 4px 46.400001525878906px rgba(0, 0, 0, 0.10)',
                                    transition: '0.5s'
                                }
                            }} key={index} className={'parent'} >
                                <CardMedia
                                    component={'img'}
                                    key={index}
                                    src={image?.img}
                                    alt={`Slide ${index + 1}`}
                                />
                            </Box>
                        ))}
                    </Marquee>
                    <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                        <Typography mb={2} variant='h6' color={'#4C164C'} > <span style={{ color: '#FABB25' }} >{'من  '}</span>
                            {'عملائنا'}</Typography>
                    </Box>
                    <Marquee velocity={isMobile ? 2 : 8}
                        radius={50} direction='ltr'>
                        {logos.map((image, index) => (
                            <Box sx={{
                                border: '1px solid #CCE1E5', background: '#e5e3e3', borderRadius: '8px', mx: 2,
                                my: 1,
                                ':hover': {
                                    background: '#CCE1E5',
                                    boxShadow: '0px 4px 46.400001525878906px rgba(0, 0, 0, 0.10)',
                                    transition: '0.5s'
                                }
                            }} key={index} className={'parent'}>
                                <CardMedia
                                    component={'img'}
                                    key={index}
                                    src={image?.img}
                                    alt={`Slide ${index + 1}`}
                                />
                            </Box>
                        ))}
                    </Marquee>
                </Box>
            </Container>
            <Transfers btn={t("transfer.btn")} />
        </>
    )
}

export default AboutUs
