import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
// import Content from './Content'
// import Pages from './Pages'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react';
import TopFooter from './TopFooter'
// import Content from './Content'

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation()
    return (
        <footer style={{ padding: isMobile ? '16px' : '20px', background: '#4C164C', textAlign: isMobile ? 'center' : 'initial' }} >
            <Container maxWidth={'lg'}>
                <TopFooter />
            </Container>
        </footer>
    )
}

export default Footer