import React from 'react'
import Header from '../components/glopal/Header'
import { Box, Container, Typography } from '@mui/material'

const InstitutionPolicy = () => {
    return (
        <>
            <Header path={'سياسة المؤسسة'} />
            <Container maxWidth={'md'} >
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: 2, py: 4,
                    justifyContent: 'space-between',
                    '@media (min-width: 1440px)': {
                        height: '50rem',
                    }
                }} >
                    <Typography textAlign={'center'} >
                        مؤسسة Mazid تلتزم بتوفير بيئة سياسية عادلة وشفافة، وتركز على السياسة الجيدة كأساس لنجاحها، من خلال تعزيز النزاهة والمساواة والمسئولية في جميع جوانب عملها.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5'  >
                            1- النزاهة: </Typography>
                        <Typography sx={{ fontSize: '1rem' }}>
                            نحن نعمل بنزاهة وأمانة في جميع أعمالنا وفي اتخاذ القرارات. نحن نلتزم بالامتناع عن أي أنشطة غير قانونية أو غير أخلاقية ونتخذ إجراءات للتصدي للفساد والاحتيال.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5'  >


                            2- المساواة:
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }}>
                            نحن نؤمن بأهمية المساواة ونعامل جميع الأفراد بعدالة واحترام، بغض النظر عن الجنس، أو العرق، أو الدين، أو الجنسية، أو العمر، أو الإعاقة، أو أي خصائص أخرى محمية بموجب القانون.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5'  >

                            3- التنوع:
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }}>نحن نقدر التنوع ونعتبره ثروة حقيقية نعمل على تعزيز بيئة عمل شاملة ومتنوعة تشجع على التعاون والإبداع والابتكار.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5'  >

                            4- السلامة والصحة المهنية:

                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }}>نحن نلتزم بتوفير بيئة عمل آمنة وصحية لجميع موظفينا وجميع الأفراد المرتبطين بأعمالنا نعمل بجد لتحقيق معايير السلامة والصحة المهنية العالية واتباع الممارسات الآمنة.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h5'  >

                            5- التعاون والشراكة:

                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }}>نحن نشجع التعاون والشراكة بين جميع أفرادنا ونعمل كفريق واحد لتحقيق أهدافنا المشتركة نشجع على تبادل الأفكار والخبرات وبناء علاقات قوية بين الأعضاء </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default InstitutionPolicy