import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import img1 from './../../assets/steps/1.png'
import img2 from './../../assets/steps/2.png'
import img3 from './../../assets/steps/3.png'
// import { useTranslation } from 'react-i18next'
import StepItem from './StepItem'
import { useTheme } from '@emotion/react'
const ServiceSteps = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container sx={{ alignItems: 'center',  }} spacing={2} >
                <Grid item md={4} xs={12}>
                    <StepItem
                    dir={isMobile ? `row-reverse`: `row`}
                        title={`تعرف على مايحتاجه مشروعك`}
                    img={img1}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StepItem
                    dir={isMobile ? `row`: `row`}
                        title={`جد حلولا مصممة خصيصا لك `}
                    img={img2}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <StepItem
                    dir={isMobile ? `row-reverse`: `row`}
                        title={` ابدأ رحلتك في التطوير والانطلاق`}
                    img={img3}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ServiceSteps
