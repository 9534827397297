import { Box, Button, CardMedia } from '@mui/material'
import React from 'react'

const ItemPakge = ({ img, children }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1, p: 1, borderRadius: '8px', background: '#F9F6F6', boxShadow: "rgba(255, 255, 255, 0.25) 0px 0px 12px", height: '100%', alignItems:{md:'normal', xs:'center'} }}>
                <Box>
                    <CardMedia
                        component={'img'}
                        src={img}
                        sx={{ width: '200px', objectFit: 'cover' }}
                    />
                    {children}
                </Box>
                <Button sx={{
                    backgroundColor: '#FABB25', color: '#fff',
                    ':hover': {
                        backgroundColor: '#FABB25',
                        color: '#fff',
                    }, width: { md: '150px', xs: '50%' },
                    p: 1,
                    my: 2
                }} >
                    <a href="mailto:info@mazidagency.com" style={{ color: '#FFF', textDecoration: 'none' }} >
                        {'اطلب الآن'}
                    </a>
                </Button>
            </Box>
        </>
    )
}

export default ItemPakge