import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import MenuIntroduction from './MenuIntroduction'
import Cat from './Cat'

const Head = () => {
    const { t } = useTranslation()
    return (
        <>
            <Box my={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap:'wrap' }} >
                <Box>
                    <Typography color={'secondary.main'} mb={1} variant='h5' >{t('PopularServices.title')}</Typography>
                    <Typography>{t('PopularServices.supTitle')}</Typography>
                </Box>
                <Box sx={{
                    position: 'relative',
                    zIndex: 0,
                    width: {md:'inherit',xs: '100%'},
                    my:{md:0, xs:2}
                }} >
                    {/* <Cat /> */}
                    {/* <MenuIntroduction /> */}
                </Box>
            </Box>
        </>
    )
}

export default Head