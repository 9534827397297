import React from 'react'
import Projects from '../Projects'

const SuccessPartners = () => {
    return (
        <>
            <Projects />
        </>
    )
}

export default SuccessPartners
