import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const Card = ({ dir= 0, icon, title }) => {
    return (
        <>
            <Box sx={{ borderRadius: '16px', border: '1px solid #D6D4D4', position: 'relative', width: '100%', p: 3, height: { md: '8rem' ,xs:'auto'}, display:'flex', alignItems:'center', my:3,
                '@media (min-width: 1440px)': {
                    height:'12rem',
                    my: 7,
                }}} >
                <Box sx={{ position:'absolute', top:'-60px', left:dir }} >
                    <CardMedia
                        alt='test'
                        component={'img'}
                        src={icon}
                    />
                </Box>
                <Typography variant='h6' textAlign={'center'} >
                    {title}
                </Typography>
            </Box>
        </>
    )
}

export default Card