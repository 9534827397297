import { Box, CardMedia, Rating, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

import { useTheme } from '@emotion/react';
const Card = ({review}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ p: '15px', border:'1px solid #F9AF33', borderRadius:'8px', display:'flex', flexDirection:'column', gap:1, alignItems:'center', mx:2, justifyContent:'space-between', height:'100%' }} > 
                {/* <Box sx={{display:'flex', gap:1, alignItems:'center'}} >
                    <Rating name="read-only" value={5} readOnly />
                    {
                        !isMobile && 
                    <Typography sx={{ color: '#8A8A8A', fontSize:'12px' }} >قبل 19 ساعة</Typography>
                    }
                    <Typography sx={{ p: 0.4, border:'2px solid #8A8A8A', borderRadius:'8px', fontSize:'12px',px:2 }} >جديدة</Typography> 
                </Box>
                <Typography sx={{ color:'#555555', textAlign:'center', mb:2 }} >
                    {review}
                </Typography>
            <Typography>أحمد محمد</Typography> */}
            <CardMedia alt='test' component={'img'} src={review} sx={{ width:'95%' }} />
            </Box>
        </>
    )
}

export default Card