import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const StepItem = ({ dir, img, title }) => {
  return (
    <>
        <Box sx={{ backgroundColor:'#F7F7F7', width:'100%', position:'relative', display:'flex', flexDirection:dir, alignItems:'center', justifyContent:'space-between', mt:'12%', px:2, }} >
              <CardMedia
          alt='test'
                  component={'img'}
                  src={img}
                  sx={{ width: '60px', height: '100px', objectFit: 'contain', mt:'-12%' }}
              />
              <Typography color={'#444242'} sx={{ fontWeight:'600' }} >{title}</Typography>
        </Box>
    </>
  )
}

export default StepItem