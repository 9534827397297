import { Box, InputLabel, Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
// import { useSnackbar } from '../../components/snackbar';

import './ContactUS.css'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
// import CheckboxesTags from './RequiredService';

const FormContact = ({ children, isDisable }) => {
    const navigate = useNavigate()
    const form = useRef()
    const [emailValid, setEmailValid] = useState(true);

    const [phoneValid, setPhoneValid] = useState(true);

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isPhoneValid = (phone) => {
        const phoneRegex = /^[0-9]{9,11}$/;
        return phoneRegex.test(phone);
    };

    const handlePhoneChange = (event) => {
        const phoneValue = event.target.value;
        setPhoneValid(isPhoneValid(phoneValue));
    };


    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmailValid(isEmailValid(emailValue));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const emailValue = e.target.elements.email.value;
        const phoneValue = e.target.elements.phone.value;

        if (!isEmailValid(emailValue) || !isPhoneValid(phoneValue)) {
            // enqueueSnackbar('Email Or Phone Is Not Valid', { variant: 'error' });
            return;
        }
        emailjs.sendForm('service_8utjvi6', 'template_wcj7wi5', form.current, 'xRgSmpwVo7Qz-fd5b')
            .then((result) => {
                console.log(result.text);
                // enqueueSnackbar('Success Send Data', { variant: 'success' });
                navigate('/done')
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, (error) => {
                // enqueueSnackbar(error, { variant: 'error' });
                console.log(error.text);
            });
        e.target.reset()
    }
    const { t } = useTranslation()
    return (
        <>
            <Box sx={{ width: '100%', height: { md: '43rem', xs: '100%' }, backgroundColor: '#F7F7F7', p: 2, borderRadius: '16px', margin: 'auto' }}>
                {children}
                
                <Box component={'form'} ref={form} onSubmit={sendEmail}
                    p={2}
                    sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Box sx={{ width: { md: '45%', xs: '100%' }, m: 'auto', my: 1 }}>
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                            {t("booking.name")}    <span style={{ color: '#F00000' }} >*</span>
                        </InputLabel>
                        <input
                            readOnly={isDisable === 'a' ? true : false}
                            style={{ backgroundColor: '#FFF' }}
                            required
                            id="name"
                            variant="filled"
                            size="medium"
                            name='name'
                            className='input'
                        />
                    </Box>
                    {/* ... Your existing code ... */}
                    <Box sx={{ width: { md: '45%', xs: '100%' }, m: 'auto', my: 1 }}>
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }}>
                            {t("booking.phone")}   <span style={{ color: '#F00000' }}>*</span>
                        </InputLabel>
                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }} >
                            <input
                                readOnly={isDisable === 'a' ? true : false}
                                required
                                style={{ backgroundColor: '#FFF', borderColor: phoneValid ? '' : '#F00000' }}
                                id="phone"
                                variant="filled"
                                size="large"
                                name='phone'
                                className='input'
                                onChange={handlePhoneChange}
                            />
                        </Box>
                        {!phoneValid && <span style={{ color: '#F00000' }}>Invalid phone number</span>}
                    </Box>

                    <Box sx={{ width: { md: '100%', xs: '100%' }, m: 'auto', my: 1 }} >
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }}>
                            {t("booking.email")}   <span style={{ color: '#F00000' }}>*</span>
                        </InputLabel>
                        <input
                            readOnly={isDisable === 'a' ? true : false}
                            required
                            style={{ backgroundColor: '#FFF', borderColor: emailValid ? '' : '#F00000' }}
                            id="email"
                            variant="filled"
                            size="large"
                            name='email'
                            className='input'
                            onChange={handleEmailChange}
                        />
                        {!emailValid && <span style={{ color: '#F00000' }}>Invalid email address</span>}
                    </Box>
                    <Box sx={{ width: { md: '95%', xs: '100%' }, m: 'auto' }}  >
                        <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                            {t("booking.message")}   <span style={{ color: '#F00000' }} >*</span>
                        </InputLabel>
                        <textarea
                            readOnly={isDisable === 'a' ? true : false}
                            required
                            style={{ backgroundColor: '#FFF', height: '75px', padding: '20px', resize: 'none', width: '100%', border:'none' }}
                            id="outlined-multiline-flexible"
                            rows={4}
                            // id="Message"
                            variant="filled"
                            size="medium"
                            name='message'
                            className='input'
                        />
                    </Box>
                    <Box py={2} width={'100%'} >
                        <Button variant="contained"
                            type="submit"
                            disabled={isDisable === 'a' ? true : false}
                            sx={{
                                backgroundColor: '#FABB25', ':hover': {
                                    backgroundColor: '#FABB25'
                                },
                                color: "#000",
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center',
my:1
                            }}>{t("booking.send")}</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default FormContact
