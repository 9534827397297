import React from 'react'
import Hero from '../components/Hero'
import PopularServices from '../components/PopularServices'
import Skills from '../components/Skills'
import Reviews from '../components/Reviews'
import ServicesYouDeserve from '../components/ServicesYouDeserve'
import SuccessPartners from '../components/SuccessPartners'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
// import Triller from '../components/Triller'
import ListBlogs from '../components/blogs/ListBlogs'

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Hero />
      {/* { 
        isMobile && 
        <Triller />
      } */} 
      <PopularServices />
      <SuccessPartners />
      <Skills />
      <Reviews />
      <ListBlogs />
      <ServicesYouDeserve />
    </>
  )
}

export default Home