import { Box, CardMedia, Container, Grid } from '@mui/material'
import React from 'react'

const ContainerBox = ({ img, children }) => {
    return (
        <>
            <Container maxWidth={"lg"}>
                <Box my={2} sx={{ display: 'flex', height: '100%',}} >
                    <Grid container sx={{ alignItems: 'center' }} >
                        <Grid item md={8} xs={12}>
                            {children}
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box>
                                <CardMedia
                                    alt='test'
                                    component={'img'}
                                    src={img}
                                    sx={{ width: { md: '100%', xs: '85%' }, margin: '20px auto' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default ContainerBox
