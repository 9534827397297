import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { services } from '../../constants';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';

const StyledMenu = styled((props) => (
  <Menu
    elevation={10}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 180, // تعيين minWidth إذا كانت الشاشة أكبر من md (لابتوب)
      left: 0, // تعيين left إذا كانت الشاشة أكبر من md (لابتوب)
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '90%', // تعيين minWidth إذا كانت الشاشة أصغر من md (تلفون)
      left: '25px !important', // تعيين left إذا كانت الشاشة أصغر من md (تلفون)
    },
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function Cat() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation()

  let lng = i18next.language

  const createHandleMenuClick = (menuItem) => {
    return () => {
      navigate(`/service/${menuItem}`)
    };
  };
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ width: "100%" }}
      >
        <Box sx={{
          display: 'flex', alignItems: 'center', gap: 2, width: { md: '110px', xs: '100%' },
          justifyContent: 'space-between'
        }} >
          <span>{t("PopularServices.sections")} </span> 
        </Box>
      </Button>
      <StyledMenu
        id="demo-customized-button"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          services.map((service) =>
            <MenuItem sx={{ position: 'relative', zIndex: 9999999 }} key={service?.id} onClick={createHandleMenuClick(service?.id)} disableRipple>
              <Typography sx={{ position: 'relative', zIndex: 9999999 }}>
                {lng === 'ar' ? service?.name : service?.nameEn}
              </Typography>
            </MenuItem>
          )
        }
      </StyledMenu>
    </div>
  );
}