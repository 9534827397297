import { Box, Button, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CardService = ({ img, color, name, price, shop, desc, path }) => {
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ padding: 2, backgroundColor: '#d6d4d43d',m:1 }} >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', p: 2 }}>
                    <CardMedia
                        alt='test'
                        component={'img'}
                        src={img}
                        sx={{ width: '75%', margin: '0 auto', height: '250px', objectFit: 'contain' }}
                    />
                    <Typography variant='h5' color={color ? color : 'secondary'} >{name}</Typography>
                    {
                        price &&
                        <Typography sx={{ color: '#FABB25', fontSize:'0.75rem' }}>{` ${price}`}</Typography>
                    }
                    {
                        shop ?
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} >
                                <Button sx={{ backgroundColor: '#FABB25', color: '#000' }} onClick={() => navigate(path)} >{desc}</Button>
                                <Button sx={{ color: '#000', border: '1px solid #8A8A8A' }} >{shop}</Button>
                            </Box> :
                            <Button sx={{ backgroundColor: '#FABB25', color: '#000' }} onClick={() => navigate(path)}>{desc}</Button>
                    }
                </Box>
            </Box>
        </>
    )
}

export default CardService