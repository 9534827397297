import { Box, CardMedia } from '@mui/material'
import React from 'react'
import facebook from './../../../assets/footer/Icon awesome-facebook.png'
// import whatsapp from './../../assets/footer/Icon simple-whatsapp.png'
import insta from './../../../assets/footer/Icon ionic-logo-instagram.png'
import snap from './../../../assets/footer/snapchat-svgrepo-com.svg'
import twet from './../../../assets/footer/twitter-svgrepo-com.svg'
import tektok from './../../../assets/footer/tictok-svgrepo-com.svg'
const Content = () => {
    const imgs = [
        {
            imgUrl: facebook,
            link: 'https://www.facebook.com/mazidagency?mibextid=ZbWKwL'
        },
        {
            imgUrl: snap,
            link: 'https://www.snapchat.com/add/mazidagency?share_id=HDX01GHfkaM&locale=en-GB'
        },
        {
            imgUrl: twet,
            link: 'https://twitter.com/mazidagencyx'
        },
        {
            imgUrl: tektok,
            link: 'https://www.tiktok.com/@mazidagency?lang=en'
        },
        {
            imgUrl: insta,
            link: 'https://www.instagram.com/mazidagencyinst/'
        },
    ]

    return (
        <>
            <Box sx={{ color: '#Fff', display: 'flex', alignItems: 'center', gap: 2, position:'relative', zIndex:9999 }} >
                {
                    imgs?.map((img, i) =>
                        <a href={img?.link} key={i} >
                        <CardMedia
                                alt='test'
                            component={'img'}
                                src={img?.imgUrl}
                            sx={{ height: '25px' }}
                            />
                    </a>
                    )
                }
            </Box>
        </>
    )
}

export default Content