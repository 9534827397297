import React from 'react'
import Carousel from 'react-material-ui-carousel'
// import img from './../../assets/reviews/Rectangle 43.png'
// import { CardMedia } from '@mui/material'

const Slider = ({ state= true, children }) => {
    return (

        <>
            <Carousel
                navButtonsAlwaysVisible={state}
                IndicatorIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="10" viewBox="0 0 32 14">
                        <rect id="Rectangle_266" data-name="Rectangle 266" width="30" height="10" rx="2" />
                    </svg>
                }
                indicatorIconButtonProps={{
                    style: {
                        padding: '0',
                        fill: '#c3c3c3',
                        '&:hover': {
                            opacity: "0.6 !important"
                        },
                    }
                }}

                activeIndicatorIconButtonProps={{
                    style: {
                        fill: '#FABB25',
                    }
                }}
                indicatorContainerProps={{
                    style: {
                        marginTop: '10px',
                        textAlign: 'center',
                        position: 'relative',
                        bottom: 0,
                        zIndex: 9
                    }
                }}
                navButtonsProps={{         
                    style: {
                        backgroundColor: '#000',
                        borderRadius: '50%',
                        color:'#FABB25'
                    }
                }}
                navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                    style: {
                        bottom: '50%',
                        top: 'unset',
                        height: 0,
                        transform: 'translateY(-50%)'
                    }
                }} 
                sx={{
                    marginTop: "25px",
                    width: '100%',
                    margin: "10px auto"
                }}>
                {children}
            </Carousel>
        </>
    )
}

export default Slider