import React from 'react' 
import HeroStart from '../components/StartNow/Hero/HeroStart'
import IncreaseYourIncome from '../components/StartNow/IncreaseYourIncome' 
import Packages from '../components/StartNow/Packages'
import Reviews from '../components/StartNow/Reviews'
import Advantages from '../components/StartNow/Advantages'
// import SuccessfulStores from '../components/StartNow/SuccessfulStores'

const StartNow = () => {
  return (
    <>
        <HeroStart />
        <IncreaseYourIncome />
        <Packages />
        <Reviews />
        <Advantages />
      {/* <SuccessfulStores /> */} 
    </>
  )
}

export default StartNow
