import React, { useState, useEffect } from 'react'
import Header from '../components/glopal/Header'
import { Box, Container, Typography } from '@mui/material'
import Item from '../components/StartNow/Packages/Item'
import CardBlogs from '../components/glopal/CardBlogs'
import { blogs } from '../constants'
import { useParams } from 'react-router-dom'

const Blog = () => {
    const { id } = useParams()
    const [Blog, setBlog] = useState(null);
    useEffect(() => {
        const foundBlog = blogs.find((Blog) => Blog.id === Number(id));
        setBlog(foundBlog);
    }, [id]);
    return (
        <>
            <Header path={'المدونة'} />
            <Container maxWidth={'lg'} >
                <Box>
                    <CardBlogs 
                        img={Blog?.image}
                        title={Blog?.name}
                        type={'9 اغسطس'}
                    />
                    <Box my={3} sx={{ p: 3, backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 46.400001525878906px rgba(0, 0, 0, 0.3)' }} >
                    <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                        <Typography mb={2} color={'#F0AF3C'} >{Blog?.titleList}</Typography>
                    </Box>
                        <ul style={{ listStyle: 'none', padding: 0 }}  >
                            {Blog?.List?.map((item, id) => <li key={id} ><Item Text={item?.name} /></li>)}
                        </ul>
                    <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                            <Typography mb={2} color={'#B4B4B4'} >{Blog?.desc}</Typography>
                    </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Blog