import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import './style.css'
const Card = ({ type, price, title, children }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { md: 2, xs: 1 }, px: 3, backgroundColor: '#F7F7F7', borderRadius: '8px', my: {md:0,xs:2}, py: {md:0,xs:2}, height: '100%', justifyContent: 'space-between', }} >
                <Box sx={{ pb: 2, my: 1, display: 'flex', flexDirection: 'column' }} >
                    <Box sx={{ position: 'relative', my: { md: 3 ,xs:3} }} className={'lineP'}>
                        <Typography variant='h6' >{type}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', my:3}}>
                        <Button sx={{
                            backgroundColor: '#000', color: '#fff',
                            ':hover': {
                                backgroundColor: '#000',
                                color: '#fff',
                            }, width: { md: '30%', xs: '40%' },
                            mr: '5%', borderRadius: '0',
                            transform: 'skewY(-5deg)',
                            fontWeight: 'bold',
                            fontSize: '20px'
                        }} >{price}</Button>
                        <Button sx={{
                            backgroundColor: '#FABB25', color: '#fff',
                            ':hover': {
                                backgroundColor: '#FABB25',
                                color: '#fff',
                            }, width: { md: '40%', xs: '50%' },
                            borderRadius: '0',
                            transform: 'skewY(-5deg)',
                            fontWeight: 'bold',
                            fontSize: '20px'
                        }} >{'دولارًا'}</Button>
                    </Box>
                    <Typography color={'#8A8A8A'} sx={{ fontWeight: '600' }} variant='h6' >{title}</Typography>
                    {children}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button sx={{
                        backgroundColor: '#FABB25', color: '#000',
                        ':hover': {
                            backgroundColor: '#FABB25',
                            color: '#000',
                        }, width: { md: '100px', xs: '35%' },
                        p: 1,
                        py: 1.5,
                        my: 2
                    }} >{'شراء'}</Button>
                </Box>
            </Box>
        </>
    )
}

export default Card