import { Box, Typography } from '@mui/material'
import React from 'react'

const SkillText = ({ title, supTitle }) => {
    return (
        <>
            <Box>
                <Typography my={1} color={'secondary.main'}  sx={{ fontWeight:'700' }} >{title}</Typography>
                <Typography color={'#000'} > {supTitle} </Typography>
            </Box>
        </>
    )
}

export default SkillText