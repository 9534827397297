import { Container, Typography, useMediaQuery } from '@mui/material' 
import React from 'react'
import Slider from '../../Slider'
import Card from './Card'
import { useTheme } from '@emotion/react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rev from './../../../assets/reviews/WhatsApp Image 2024-04-21 at 1.22.14 PM.png'
const Reviews = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        }
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Container maxWidth={'lg'}>
                <Typography sx={{ my: 2, textAlign: { md: 'inherit', xs: 'center' } }} variant='h5' >
                    <span style={{ color:'#FABB25' }} >الأرباح</span>  
                </Typography>
                {
                    isMobile ? <Slider state={false} > 
                        <Card review={rev} />
                        <Card review={rev} />
                        <Card review={rev} />
                        <Card review={rev} /> 
                    </Slider>    
                    : <Carousel
                        autoPlay={true}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        infinite={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="transform 300ms ease-in-out"
                        transitionDuration={1000}
                        containerclassName="carousel-container"
                        dotListclassName="custom-dot-list-style"
                        itemclassName="carousel-item-padding-40-px"
                        rewindWithAnimation={true}
                        removeArrowOnDeviceType={['desktop', 'mobile', 'table']}
                    >
                            <Card review={rev} />
                            <Card review={rev} />
                            <Card review={rev} />
                            <Card review={rev} /> 
                    </Carousel>
                }
                
            </Container>
        </>
    )
}

export default Reviews