import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const Transfers = ({ btn }) => {
    const location = useLocation();
    let path = location?.pathname?.split('/')[2]
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <>
            <Box sx={{ backgroundColor: '#FAF7F7', p: { md: 4, xs: 2 }, mt: {md: 2, xs:4} }} >
                <Container maxWidth={'lg'} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: { md: 'row', xs: 'column' }, gap: { md: 0, xs: 2 } }} >
                        <Typography>{t("transfer.desc")}</Typography>

                        {
                            btn ?
                                <Button onClick={() => navigate('/services')} sx={{
                                    backgroundColor: '#FABB25', color: '#000',
                                    ':hover': {
                                        backgroundColor: '#FABB25'
                                    }, width: '100px'
                                }} >{t("transfer.btn")}</Button>
                                :
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} >
                                    <Button onClick={() => navigate('/services')} sx={{
                                        backgroundColor: '#FABB25', color: '#000',
                                        ':hover': {
                                            backgroundColor: '#FABB25'
                                        },
                                    }}>{t("transfer.search")}</Button>
                                    {path === '9' ||

                                        <Button sx={{ backgroundColor: 'transparent', color: '#000', border: '1px solid #ccc' }} >{t("transfer.pay")}</Button>
                                    }
                                </Box>

                        }
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Transfers