import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import home from './../../assets/iconNav/Icon material-home.png'
import about from './../../assets/iconNav/question.png'
import service from './../../assets/iconNav/repairing-service.png'
import work from './../../assets/iconNav/man-working-on-a-laptop-from-side-view.png'
import howWork from './../../assets/iconNav/Icon material-group-work.png'
import book from './../../assets/iconNav/Icon awesome-save.png'
import startNow from './../../assets/iconNav/gains (1).png'
import './style.css'
export default function NavDrawer({ setDrawer, drawer }) {
    const { t } = useTranslation()
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="right"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    className="drw"
                    classes={{  }}
                    sx={{
                        '.MuiDrawer-paper': {
                            color: '#fff!important',
                            backgroundColor: '#000000e0 !important',
                            height: '75% !important ',
                            borderBottomLeftRadius: '16px',
                            borderBottomRightRadius: '16px',
                            overflowY:'auto'
} }}
                >
                    <Box
                        sx={{
                            width: '75vw',
                            gap: 2,
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                        }}
                        onClose={() => setDrawer(false)}>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 15,
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => setDrawer(false)}
                                >
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} > 
                            <Box sx={{ padding:1, borderRadius:'50%' }} className={'cov'} >
                                <CardMedia
                                 alt='test'
                                    component={'img'}
                                    src={home}
                                    sx={{ height: '16px', width: '16px' }}
                                />
                            </Box>
                                <Typography  >{t('linksPages.home')}</Typography>
                        </Box>
                            </Link>
                            
                                    <Link
                                        to="/about"
                                        style={{
                                            textDecoration: "none",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => setDrawer(false)}
                                    >
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} > 
                            <Box sx={{ padding:1, borderRadius:'50%' }} className={'cov'} >
                                <CardMedia
                                 alt='test'
                                    component={'img'}
                                    src={about}
                                    sx={{ height: '16px', width: '16px' }}
                                />
                            </Box>
                                <Typography>{t('linksPages.aboutUs')}</Typography>
                        </Box>
                        </Link>
                                <Link
                                    to="/services"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 15,
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => setDrawer(false)}
                                >
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} > 
                            <Box sx={{ padding:1, borderRadius:'50%' }} className={'cov'} >
                                <CardMedia
                                 alt='test'
                                    component={'img'}
                                    src={service}
                                    sx={{ height: '16px', width: '16px' }}
                                />
                            </Box>
                                <Typography> {t('linksPages.OurServices')}</Typography>
                        </Box>
                        </Link>
                                {/* <Link
                                    to="/resources"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 15,
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => setDrawer(false)}
                                >
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} > 
                            <Box sx={{ padding:1, borderRadius:'50%' }} className={'cov'} >
                                <CardMedia
                                 alt='test'
                                    component={'img'}
                                    src={work}
                                    sx={{ height: '16px', width: '16px' }}
                                />
                            </Box>
                                <Typography>{t('linksPages.resources')}</Typography>
                        </Box>
                        </Link> */}
                                <Link
                                    to="/contact"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 15,
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => setDrawer(false)}
                                >
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} > 
                            <Box sx={{ padding: 1, borderRadius: '50%', backgroundColor: '#F7F7F7' }} className={'parentNav'} >
                                <CardMedia
                                 alt='test'
                                    component={'img'}
                                    src={howWork}
                                    sx={{ height: '16px', width: '16px' }}
                                />
                            </Box>
                                <Typography>{t('linksPages.ContactUs')}</Typography>
                        </Box>
                        </Link>
                        <Link
                            to="/booking"
                            style={{
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} > 
                                    <Box sx={{ padding:1, borderRadius:'50%' }} className={'cov'} >
                                        <CardMedia
                                         alt='test'
                                            component={'img'}
                                            src={book}
                                            sx={{ height: '16px', width: '16px' }}
                                        />
                                    </Box>
                               <Typography>{t('linksPages.booking')}</Typography>
                        </Box>
                        </Link>

                        <Link
                            className="link"
                            to="/start-now"
                            style={{
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} className={'parentNav'} >
                                <Box sx={{ padding: 1, borderRadius: '50%' }} className={'cov'} >
                                    <CardMedia
                                     alt='test'
                                        component={'img'}
                                        src={startNow}
                                        sx={{ height: '16px', width: '16px' }}
                                    />
                                </Box>
                                <Typography>{t('linksPages.startNow')}</Typography>
                        </Box>
                        </Link>
                        {/* <TransLang /> */}
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
