import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CardBlog from './CardBlog'
import { blogs } from '../../constants'

const ListBlogs = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                    <Typography mb={2} variant='h5' color={'#F0AF3C'} >{'المدونه'}</Typography>
                </Box>
                <Grid container spacing={1}>
                    {
                        blogs.slice(0, 4).map((blog) =>
                            <Grid item md={3} xs={12} key={blog?.id}>
                                <CardBlog
                                    img={blog.image}
                                    title={blog.name}
                                    desc={blog.desc?.slice(0, 100)}
                                    path={`/blog/${blog?.id}`}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        </>
    )
}

export default ListBlogs