import { Box, Button, useMediaQuery } from '@mui/material'
import React from 'react'
import ListItem from './ListItem'
import { useTranslation } from 'react-i18next'
import './style.css'
import { useTheme } from '@emotion/react'
const Packages = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <div className={isMobile || "bgPackages"}>
        {/* <Container maxWidth={'lg'}> */} 
        <Box my={3} sx={{ p: 3, backgroundColor: '#fff', borderRadius: '8px', position: { md: 'absolute', xs: 'relative' }, top: { md: '-90px' ,xs:'0'}, boxShadow:'0px 4px 46.400001525878906px rgba(0, 0, 0, 0.3)' }} >
            <ListItem />
            <Box textAlign={'center'} >
              <Button sx={{
                backgroundColor: '#FABB25', color: '#fff',
                ':hover': {
                  backgroundColor: '#FABB25',
                  color: '#fff',
                }, width: { md: '150px', xs: '50%' },
                p: 1,
                my: 2
              }} >
              <a href="mailto:info@mazidagency.com" style={{ color:'#FFF', textDecoration:'none' }} >
                {t("startNow.btnOrder")}
                </a>
                </Button>
            </Box>
          </Box>
        {/* </Container> */}
      </div>
    </>
  )
}

export default Packages
