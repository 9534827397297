import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Pages = () => {
    const {t} = useTranslation()
  return (
    <>
      <Box sx={{ color: '#Fff', display: 'flex', alignItems: 'center', gap: { md: 2 ,xs:0.5},position:'relative', zIndex:9991 }} >
        <Link
          className="link"
          to="/privacy"
          style={{textDecoration: "none",}}
        >
            <Typography sx={{ fontSize:{md:'1rem',xs:'0.8rem'} }} >{t("footer.page1")}</Typography>
        </Link>
        <Link
          className="link"
          to="/institution"
          style={{textDecoration: "none",}}
          >
            <Typography sx={{ fontSize:{md:'1rem',xs:'0.8rem'} }} >{t("footer.page2")}</Typography>
          </Link>
        <Link
          className="link"
          to="/site-map"
          style={{ textDecoration: "none", }}
        >
          
            <Typography sx={{ fontSize:{md:'1rem',xs:'0.8rem'} }} >{t("footer.page3")}</Typography>
        </Link>
        </Box>
    </>
  )
}

export default Pages