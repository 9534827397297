import React, { useEffect, useState } from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import ContainerBox from '../components/glopal/ContainerBox'
import Transfers from '../components/glopal/Transfers'
import {
    Box, Container, Grid, Typography,
    useMediaQuery,

} from '@mui/material'
import { useParams } from 'react-router-dom'
import { services } from '../constants'
import i18next from 'i18next'
import ServiseDesc from '../components/service/ServiseDesc'
import ServiceLest from '../components/service/ServiceLest'
import ServiceSteps from '../components/service/ServiceSteps'
import BritishCompany from '../components/BritishCompany/BritishCompany'
import ArticleService from '../components/service/ArticleService'
import ItemPakge from '../components/service/ItemPakge'
import Item from '../components/BritishCompany/Item'
import Slider from '../components/Slider'
import { useTheme } from '@emotion/react'
const Service = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    let lng = i18next.language
    const { id } = useParams()
    const [service, setService] = useState(null);
    console.log("🚀 ~ Service ~ service:", service)
    useEffect(() => {
        const foundService = services.find((service) => service.id === Number(id));
        setService(foundService);
    }, [id]);
    const { t } = useTranslation()
    const listArticle = service?.article?.list
    if (id === '9') {
        return (
            <>
                <BritishCompany />
                <Transfers />
            </>
        );
    }
    return (
        <>
            <Header path={t("linksPages.OurServices")} />
            <ContainerBox img={service?.img} >
                <Box>
                    <ServiseDesc
                        name={lng === 'en' ? service?.nameEn : service?.name}
                        title={lng === 'en' ? service?.titleEn : service?.title}
                        desc={lng === 'en' ? service?.descEn : service?.desc}
                    />
                </Box>
                <Box my={2} position={'relative'}>
                    <ServiceLest
                        serviceTo={lng === 'en' ? service?.servToEn : service?.servTo}
                        item1={lng === 'en' ? service?.listEn?.item1 : service?.list?.item1}
                        item2={lng === 'en' ? service?.listEn?.item2 : service?.list?.item2}
                        item3={lng === 'en' ? service?.listEn?.item3 : service?.list?.item3}
                    />
                </Box>
            </ContainerBox>
            <Container maxWidth={'lg'} >
                <ArticleService title={service?.article?.title} desc={service?.article?.desc} because={service?.article?.because} >
                    {listArticle &&
                        listArticle?.map((item, i) => <Box
                            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                            key={i} >
                            {
                                item?.title &&
                                <Typography>{item?.title}</Typography>
                            }
                            {
                                item?.desc &&
                                <Typography>{item?.desc}</Typography>
                            }
                        </Box>
                        )
                    }
                </ArticleService>
                {service?.article?.because2 &&
                    <ArticleService because={service?.article?.because2} >
                        {
                            service?.article?.list2?.map((item, i) => <Box
                                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} key={i} >
                                {
                                    item?.title &&
                                    <Typography>{item?.title}</Typography>
                                }
                                {
                                    item?.desc &&
                                    <Typography>{item?.desc}</Typography>
                                }
                            </Box>
                            )
                        }
                    </ArticleService>
                }
                {service?.article?.because3 &&
                    <ArticleService because={service?.article?.because3} >
                        {
                            service?.article?.list3?.map((item, i) => <Box
                                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} key={i} >
                                {
                                    item?.title &&
                                    <Typography>{item?.title}</Typography>
                                }
                                {
                                    item?.desc &&
                                    <Typography>{item?.desc}</Typography>
                                }
                            </Box>
                            )
                        }
                    </ArticleService>
                }
                {service?.article?.because4 &&
                    <ArticleService because={service?.article?.because4} >
                        {
                            service?.article?.list4?.map((item, i) => <Box
                                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} key={i} >
                                {
                                    item?.title &&
                                    <Typography>{item?.title}</Typography>
                                }
                                {
                                    item?.desc &&
                                    <Typography>{item?.desc}</Typography>
                                }
                            </Box>
                            )
                        }
                    </ArticleService>
                }
                {service?.article?.because5 &&
                    <ArticleService because={service?.article?.because5} >
                        {
                            service?.article?.list5?.map((item, i) => <Box
                                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} key={i} >
                                {
                                    item?.title &&
                                    <Typography>{item?.title}</Typography>
                                }
                                {
                                    item?.desc &&
                                    <Typography>{item?.desc}</Typography>
                                }
                            </Box>
                            )
                        }
                    </ArticleService>
                }
                {service?.article?.because6 &&
                    <ArticleService because={service?.article?.because6} >
                        {
                            service?.article?.list6?.map((item, i) => <Box
                                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} key={i} >
                                {
                                    item?.title &&
                                    <Typography>{item?.title}</Typography>
                                }
                                {
                                    item?.desc &&
                                    <Typography>{item?.desc}</Typography>
                                }
                            </Box>
                            )
                        }
                    </ArticleService>
                }
            </Container>
            <Box>
                <Container maxWidth="lg">
                    {service?.pakges &&
                        <>
                            <Typography variant='h6' color={'#000'} sx={{ my: 2 }} >{service?.pakges?.title}</Typography>
                        {!isMobile ?
                            <Grid container sx={{}} spacing={2} >
                                {service?.pakges?.list?.map((item) =>
                                    <Grid item lg={3} md={6} xs={12} key={item?.id} >
                                        <Box sx={{ height: { md:  service?.pakges?.title === "أسعار إدارة صفحات السوشيال ميديا" ? '46rem':'62rem',xs:'57rem' } }} >
                                            <ItemPakge img={item?.img}>
                                                <ul style={{ listStyle: 'none', padding: 0 }}  >
                                                    {item?.list?.map((res, i) => <li key={i} ><Item Text={res} /></li>)}
                                                </ul>
                                            </ItemPakge>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        :
                            <Slider state={false} >
                                {service?.pakges?.list?.map((item) => <Box sx={{ height: service?.pakges?.title === "أسعار إدارة صفحات السوشيال ميديا" ? '46rem': '62rem' }} key={item?.id} >
                                    <ItemPakge img={item?.img}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}  >
                                            {item?.list?.map((res, i) => <li key={i} ><Item Text={res} /></li>)}
                                        </ul>
                                    </ItemPakge>
                                </Box>)}
                        </Slider>
                        }
                        </>
                    }
                </Container>
            </Box>
            <Box>
                <Container maxWidth={'lg'} >
                    <Typography variant='h5' my={2} sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                        {t("service.descSteps")}
                    </Typography>
                    <ServiceSteps />
                </Container>
            </Box>
            <Transfers />
        </>
    )
}

export default Service
